export default function IconButtonEdit() {
  return (
    <svg id="btn-secondary-icon" xmlns="http://www.w3.org/2000/svg" height={21} width={21} viewBox="0 0 21 21">
      <g id="Gruppe_607" data-name="Gruppe 607">
        <g id="icon-edit-21px">
          <path id="Pfad_583" data-name="Pfad 583" d="M0,0H21V21H0Z" fill="none" />
          <g id="streamlinehq-pencil-1-interface-essential-48" transform="translate(1.276 1.276)">
            <path
              id="Pfad_584"
              data-name="Pfad 584"
              d="M16.954,1.551a2.75,2.75,0,0,0-3.907.026L2.088,12.536.75,17.755l5.219-1.338L16.927,5.458a2.75,2.75,0,0,0,.026-3.907Z"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              id="Pfad_585"
              data-name="Pfad 585"
              d="M16.606,2.26,20.5,6.152"
              transform="translate(-3.888 -0.365)"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              id="Pfad_586"
              data-name="Pfad 586"
              d="M14.512,4.354,18.4,8.246"
              transform="translate(-3.37 -0.883)"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              id="Pfad_587"
              data-name="Pfad 587"
              d="M2.521,16.345l3.9,3.888"
              transform="translate(-0.428 -3.825)"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
