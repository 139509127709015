import {
  PropsFachgruppeBerufszweigSelect,
  PropsFirmenSelect,
  PropsLaenderDataSelect,
  PropsMitgliedschaftDataSelect,
} from "../model/PropsInterfaces";
import { getNameByWKIDNeu } from "../model/Kammern";

export function FirmenResultSelect(options: PropsFirmenSelect) {
  var items: JSX.Element[] = [];
  items.push(
    <option value={options.userInfo?.rollePersoenlich} key={options.userInfo?.rollePersoenlich}>
      Persönlich
    </option>
  );
  const roles: JSX.Element[] | undefined = options.userInfo?.rollenMitglied?.map((element) => (
    <option value={element.roleID} key={element.roleID}>
      {element.firmenbezeichnung}
    </option>
  ));
  roles?.forEach((role) => {
    items.push(role);
  });
  return (
    <>
      {(options.userInfo?.rollenMitglied?.length! > 0 && (
        <select
          className="form-select mb-4"
          id="extra"
          aria-label="Wählen Sie Ihre Firma aus"
          value={options.userInfo?.authenticatedUserRoleId}
          onChange={options.onChangeHandler}
        >
          {items}
        </select>
      )) ||
        ""}
    </>
  );
}

export function FachgruppeBerufszweigSelect(options: PropsFachgruppeBerufszweigSelect) {
  const items = options.values?.map((element) => (
    <option value={element} key={element}>
      {element}
    </option>
  ));
  return (
    <select className="form-select" aria-label={options.label} value={options.value} onChange={options.onChangeHandler}>
      {items}
    </select>
  );
}

export function BundeslaenderResultSelect(options: PropsMitgliedschaftDataSelect) {
  const items = (
    <>
      {(options.addAlleOption === undefined || options.addAlleOption) && (
        <option value="all" key="all">
          {!options.alleOptionName && "Alle Bundesländer"}
          {options.alleOptionName && <>{options.alleOptionName}</>}
        </option>
      )}
      {options.data?.kammern?.map((element) => (
        <option value={element.wknrNeu} key={element.wknrNeu}>
          {getNameByWKIDNeu(element.wknrNeu || "")}
        </option>
      ))}
    </>
  );
  return (
    <>
      <select
        className="form-select"
        id="bl-filter"
        aria-label="Filtern nach Bundesland"
        value={options.value}
        onChange={options.onChangeHandler}
        disabled={!!options.loading}
      >
        {items}
      </select>
    </>
  );
}

export function LaenderSelect(options: PropsLaenderDataSelect) {
  const items = (
    <>
      {options.data?.map((element) => (
        <option value={element.iso} key={element.iso}>
          {element.bezeichnung}
        </option>
      ))}
    </>
  );
  return (
    <select
      className="form-select"
      id="bl-filter"
      aria-label="Auswahl Staat"
      value={options.value}
      onChange={options.onChangeHandler}
      style={options.style}
    >
      {items}
    </select>
  );
}
