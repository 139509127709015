export enum Berechtigungsstatus {
  AKTIV = "Aktiv",
  RUHEND = "Ruhend"
}

export enum BerechtigungsFilterStatus {
  AKTIV = "AKTIV",
  RUHEND = "RUHEND",
  ALLE = "ALLE",
  GELOESCHT = "GELOESCHT",
  AKTIV_RUHEND = "AKTIV_RUHEND"
}