import { AxiosPromise } from "axios";
import { CookieApi } from "../../../api";
import { BASE_PATH_UNAUTHORIZED } from "../../../constants/constants";
import { axiosInstance } from "../../../factory/AxiosFactory";

class CookieService {
  private static _instance: CookieService;

  private api = new CookieApi(undefined, BASE_PATH_UNAUTHORIZED, axiosInstance);

  private constructor() {}

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getCookies(): AxiosPromise<any> {
    return this.api.getCookies();
  }
  
}

export const cookieService = CookieService.Instance;
