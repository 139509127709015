export default function IconPlus() {
  return (
    <svg
      className="icon-add"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <g
        id="streamlinehq-add-circle-interface-essential-20"
        transform="translate(-0.25 -0.25)"
      >
        <g id="Gruppe_2649" data-name="Gruppe 2649">
          <path
            id="Pfad_2892"
            data-name="Pfad 2892"
            d="M12,7.5v7.6"
            transform="translate(-1.75 -1.049)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Pfad_2893"
            data-name="Pfad 2893"
            d="M7.5,12h7.6"
            transform="translate(-1.049 -1.75)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <path
          id="Pfad_2894"
          data-name="Pfad 2894"
          d="M.75,10.25a9.5,9.5,0,1,0,9.5-9.5,9.5,9.5,0,0,0-9.5,9.5Z"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
