import { AxiosPromise } from "axios";
import { UserApi, BasicUserInfoResponseType } from "../../../api";
import { BASE_PATH } from "../../../constants/constants";
import { axiosInstance } from "../../../factory/AxiosFactory";

class UserService {
  private static _instance: UserService;

  private api = new UserApi(undefined, BASE_PATH, axiosInstance);

  private constructor() {}

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getBasicUserInfo(): AxiosPromise<BasicUserInfoResponseType> {
    return this.api.getUserBasicInfo();
  }
  
}

export const userService = UserService.Instance;

export const getDescriptionNameByRoleId = (userInfo: BasicUserInfoResponseType|undefined): string|undefined => {
  var autenticatedRoleInfo = userInfo?.rollenInfos?.find((role) => role.id === userInfo.authenticatedUserRoleId);
  return autenticatedRoleInfo?.description;
};