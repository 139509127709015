export default function IconUpload() {
  return (
    <svg
      id="icon-upload"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <g
        id="Gruppe_807"
        data-name="Gruppe 807"
        transform="translate(-180 -1831)"
      >
        <rect
          id="Rechteck_201"
          data-name="Rechteck 201"
          width="100"
          height="100"
          transform="translate(180 1831)"
          fill="none"
        ></rect>
      </g>
      <g
        id="streamlinehq-common-file-text-download-files-folders-90"
        transform="translate(6.5 5)"
      >
        <circle
          id="Ellipse_25"
          data-name="Ellipse 25"
          cx="22.5"
          cy="22.5"
          r="22.5"
          transform="translate(87.188 87.188) rotate(180)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        ></circle>
        <g
          id="Gruppe_2104"
          data-name="Gruppe 2104"
          transform="translate(129.375 129.375) rotate(180)"
        >
          <path
            id="Pfad_595"
            data-name="Pfad 595"
            d="M0,0V22.5"
            transform="translate(64.688 53.438)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="1"
          ></path>
          <line
            id="Linie_80"
            data-name="Linie 80"
            x1="8.438"
            y1="8.438"
            transform="translate(56.25 67.5)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          ></line>
          <line
            id="Linie_81"
            data-name="Linie 81"
            y1="8.438"
            x2="8.438"
            transform="translate(64.688 67.5)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          ></line>
        </g>
        <line
          id="Linie_82"
          data-name="Linie 82"
          x2="39.375"
          transform="translate(14.063 25.313)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        ></line>
        <line
          id="Linie_83"
          data-name="Linie 83"
          x2="22.5"
          transform="translate(14.063 42.188)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        ></line>
        <line
          id="Linie_84"
          data-name="Linie 84"
          x2="14.063"
          transform="translate(14.063 59.063)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        ></line>
        <path
          id="Pfad_366"
          data-name="Pfad 366"
          d="M28.125,75.938H8.438a5.625,5.625,0,0,1-5.625-5.625V8.438A5.625,5.625,0,0,1,8.438,2.813H48.3A5.624,5.624,0,0,1,52.27,4.458L63.041,15.229A5.625,5.625,0,0,1,64.688,19.2v8.921"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        ></path>
      </g>
    </svg>
  );
}
