import { Spinner } from "../view/Spinner";

export function LoadingScreen() {
  return (
    <div className="spinner">
      <div className="container d-flex flex-column align-items-center justify-content-center">
        <h3>Ihre Anfrage wird bearbeitet</h3>
        <Spinner />
      </div>
    </div>
  );
}
