import { AxiosPromise } from "axios";
import { MitgliedschaftApi, MitgliedschaftRequestType, MitgliedschaftResponseType, FoPaketeResponseType, KontaktdatenResponseType, KontaktdatenRequestModel, KontaktdatenStatusModel } from "../../../api";
import { BASE_PATH } from "../../../constants/constants";
import { axiosInstance } from "../../../factory/AxiosFactory";

class MitgliedschaftService {
  private static _instance: MitgliedschaftService;

  private api = new MitgliedschaftApi(undefined, BASE_PATH, axiosInstance);

  private constructor() {}

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getInfo(): AxiosPromise<void> {
    return this.api.getMitgliedschaftInfo();
  }

  public getStammblattStandorte(mitgliedschaftRequest: MitgliedschaftRequestType): AxiosPromise<MitgliedschaftResponseType> {
    return this.api.getMitgliederServicesStammblattStandorte(mitgliedschaftRequest);
  }

  public getStammblattBundeslaender(mitgliedschaftRequest: MitgliedschaftRequestType): AxiosPromise<MitgliedschaftResponseType> {
    return this.api.getMitgliederServicesStammblattBundeslaender(mitgliedschaftRequest);
  }

  public createStammblatt(mitgliedschaftRequest: MitgliedschaftRequestType): AxiosPromise<MitgliedschaftResponseType> {
    return this.api.saveMitgliederServicesStammblattBestellung(mitgliedschaftRequest);
  }

  public searchOrganisations(mitgliedschaftRequest: MitgliedschaftRequestType): AxiosPromise<MitgliedschaftResponseType> {
    return this.api.getMitgliederServicesStammblattSuche(mitgliedschaftRequest);
  }

  public searchBetreuerOrganisations(mitgliedschaftRequest: MitgliedschaftRequestType): AxiosPromise<MitgliedschaftResponseType> {
    return this.api.getMitgliederServicesNBWBBetreuerSearchOrganisationen(mitgliedschaftRequest);
  }

  public getBetreuerLetzteSuchen(mitgliedschaftRequest: MitgliedschaftRequestType): AxiosPromise<MitgliedschaftResponseType> {
    return this.api.getMitgliederServicesNBWBBetreuerLetzteOrganisationen(mitgliedschaftRequest);
  }

  public searchBerechtigungen(mitgliedschaftRequest: MitgliedschaftRequestType): AxiosPromise<MitgliedschaftResponseType> {
    return this.api.getMitgliederServicesNBWBMGBerechtigungen(mitgliedschaftRequest);
  }

  public saveMeldungen(mitgliedschaftRequest: MitgliedschaftRequestType): AxiosPromise<MitgliedschaftResponseType> {
    return this.api.saveMitgliederServicesNBWBMGMeldungen(mitgliedschaftRequest);
  }

  public getFoPakete(mitgliedschaftRequest: MitgliedschaftRequestType): AxiosPromise<FoPaketeResponseType> {
    return this.api.getFoPakete(mitgliedschaftRequest);
  }

  public getKontaktdaten(mitgliedschaftRequest: MitgliedschaftRequestType): AxiosPromise<KontaktdatenResponseType> {
    return this.api.getKontaktdaten(mitgliedschaftRequest);
  }

  public createKontaktdatenChangeRequest(kontaktdatenRequest: KontaktdatenRequestModel): AxiosPromise<void> {
    return this.api.createKontaktdatenChangeRequest(kontaktdatenRequest);
  }
  public getKontaktdatenChangeRequestStatus(mitgliedschaftRequest: MitgliedschaftRequestType): AxiosPromise<KontaktdatenStatusModel> {
    return this.api.checkKontaktdatenChangeRequestStatus(mitgliedschaftRequest);
  }

  public isKontaktdatenEnabled(): AxiosPromise<any> {
    return this.api.isKontaktdatenEnabled();
  }
  
}

export const mitgliedschaftService = MitgliedschaftService.Instance;
