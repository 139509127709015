import { action, computed, makeObservable, observable } from "mobx";

class LoadingStatusProvider {
  loadingCount: number = 0;
  private static _instance: LoadingStatusProvider;

  constructor() {
    makeObservable(this, {
      loadingCount: observable,
      increaseLoadingCount: action,
      decreaseLoadingCount: action,
      isLoading: computed,
    });
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  increaseLoadingCount() {
    this.loadingCount++;
  }

  decreaseLoadingCount() {
    this.loadingCount--;
  }

  get isLoading(): boolean {
    return this.loadingCount > 0;
  }
}

export const loadingStatusProvider = LoadingStatusProvider.Instance;
