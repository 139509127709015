import { MitgliedschaftResponseType } from "../../../api";

export enum KammernummerEnum {
  WIEN = "1",
  NIEDEROESTERREICH = "3",
  OBEROESTERREICH = "4",
  SALZBURG = "5",
  STEIERMARK = "6",
  KAERNTEN = "7",
  TIROL = "8",
  VORARLBERG = "9",
  BURGENLAND = "10"
}

export enum KammernamenEnum {
  WIEN = "Wien",
  NIEDEROESTERREICH = "Niederösterreich",
  OBEROESTERREICH = "Oberösterreich",
  SALZBURG = "Salzburg",
  STEIERMARK = "Steiermark",
  KAERNTEN = "Kärnten",
  TIROL = "Tirol",
  VORARLBERG = "Vorarlberg",
  BURGENLAND = "Burgenland"
}

export const alleBundeslaender: MitgliedschaftResponseType = {
  kammern: [
    {
      name: KammernamenEnum.BURGENLAND.valueOf(),
      wknrNeu: KammernummerEnum.BURGENLAND.valueOf(),
    },
    {
      name: KammernamenEnum.KAERNTEN.valueOf(),
      wknrNeu: KammernummerEnum.KAERNTEN.valueOf(),
    },
    {
      name: KammernamenEnum.NIEDEROESTERREICH.valueOf(),
      wknrNeu: KammernummerEnum.NIEDEROESTERREICH.valueOf(),
    },
    {
      name: KammernamenEnum.OBEROESTERREICH.valueOf(),
      wknrNeu: KammernummerEnum.OBEROESTERREICH.valueOf(),
    },
    {
      name: KammernamenEnum.SALZBURG.valueOf(),
      wknrNeu: KammernummerEnum.SALZBURG.valueOf(),
    },
    {
      name: KammernamenEnum.STEIERMARK.valueOf(),
      wknrNeu: KammernummerEnum.STEIERMARK.valueOf(),
    },
    {
      name: KammernamenEnum.TIROL.valueOf(),
      wknrNeu: KammernummerEnum.TIROL.valueOf(),
    },
    {
      name: KammernamenEnum.VORARLBERG.valueOf(),
      wknrNeu: KammernummerEnum.VORARLBERG.valueOf(),
    },
    {
      name: KammernamenEnum.WIEN.valueOf(),
      wknrNeu: KammernummerEnum.WIEN.valueOf(),
    },
  ],
};

export const getNameByWKIDNeu = (wkidNeu: string): string => {
  switch (wkidNeu) {
    case KammernummerEnum.BURGENLAND.valueOf():
      return KammernamenEnum.BURGENLAND.valueOf();
    case KammernummerEnum.KAERNTEN.valueOf():
      return KammernamenEnum.KAERNTEN.valueOf();
    case KammernummerEnum.NIEDEROESTERREICH.valueOf():
      return KammernamenEnum.NIEDEROESTERREICH.valueOf();
    case KammernummerEnum.OBEROESTERREICH.valueOf():
      return KammernamenEnum.OBEROESTERREICH.valueOf();
    case KammernummerEnum.SALZBURG.valueOf():
      return KammernamenEnum.SALZBURG.valueOf();
    case KammernummerEnum.STEIERMARK.valueOf():
      return KammernamenEnum.STEIERMARK.valueOf();
    case KammernummerEnum.TIROL.valueOf():
      return KammernamenEnum.TIROL.valueOf();
    case KammernummerEnum.VORARLBERG.valueOf():
      return KammernamenEnum.VORARLBERG.valueOf();
    case KammernummerEnum.WIEN.valueOf():
      return KammernamenEnum.WIEN.valueOf();
    default:
      return KammernamenEnum.WIEN.valueOf();
  }
};