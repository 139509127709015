import { useRef, useState } from "react";
import { PropsMitgliedschaftDataSelect } from "../model/PropsInterfaces";
import IconTooltip from "../svgs/IconTooltip";

import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";

export default function StammblattBerechtigungsauswahl(options: PropsMitgliedschaftDataSelect) {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipClickTarget = useRef(null);

  return (
    <div className="element-radio floating-label col-4">
      <div className="content form-group mb-4">
        <div className="text-bold d-flex">
          <p className="infotitle">Berechtigungsstatus</p>
          <span className="icon-info" ref={tooltipClickTarget} onClick={() => setShowTooltip(!showTooltip)}>
            <IconTooltip />
          </span>
          <Overlay target={tooltipClickTarget.current} show={showTooltip} placement="top">
            {(props) => (
              <Tooltip id="overlay-example" {...props}>
                Bestimmt welche Berechtigungen letztlich gedruckt werden. Es werden nur Standorte angezeigt, die
                zumindest eine Berechtigung der getroffenen Filterauswahl aufweisen.
              </Tooltip>
            )}
          </Overlay>
        </div>
        <div className="element-combi-radio">
          <div className="content form-group d-flex flex-row">
            {(options.loading && <>Filter werden geladen...</>) || (
              <>
                {(!options.searched || options.userInfo?.explizitStammblattErsteller) &&
                  ((options.data?.hasStatusAktiv && options.data?.hasStatusRuhend) ||
                    (options.data?.hasStatusAktiv && options.data?.hasStatusGeloescht) ||
                    (options.data?.hasStatusRuhend && options.data?.hasStatusGeloescht)) && (
                    <div className="form-check me-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="filterStatus"
                        id="filterStatusAlle"
                        value={"ALLE"}
                        checked={options.value === "ALLE"}
                        onChange={(e) => options.onChangeHandler(e)}
                      />
                      <label className="form-check-label" htmlFor="filterStatusAlle">
                        Alle
                      </label>
                    </div>
                  )}
                {options.searched &&
                  options.data?.hasStatusAktiv &&
                  options.data?.hasStatusRuhend &&
                  !options.userInfo?.explizitStammblattErsteller && (
                    <div className="form-check me-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="filterStatus"
                        id="filterStatusAktivRuhend"
                        value={"AKTIV_RUHEND"}
                        checked={options.value === "AKTIV_RUHEND"}
                        onChange={(e) => options.onChangeHandler(e)}
                      />
                      <label className="form-check-label" htmlFor="filterStatusAktivRuhend">
                        Alle
                      </label>
                    </div>
                  )}
                {options.data?.hasStatusAktiv && (
                  <div className="form-check me-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="filterStatus"
                      id="filterStatusAktiv"
                      value={"AKTIV"}
                      onChange={(e) => options.onChangeHandler(e)}
                      checked={options.value === "AKTIV"}
                    />
                    <label className="form-check-label" htmlFor="filterStatusAktiv">
                      Aktiv
                    </label>
                  </div>
                )}
                {options.data?.hasStatusRuhend && (
                  <div className="form-check me-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="filterStatus"
                      id="filterStatusRuhend"
                      value={"RUHEND"}
                      onChange={(e) => options.onChangeHandler(e)}
                      checked={options.value === "RUHEND"}
                    />
                    <label className="form-check-label" htmlFor="filterStatusRuhend">
                      Ruhend
                    </label>
                  </div>
                )}
                {options.data?.hasStatusGeloescht &&
                  (!options.searched || options.userInfo?.explizitStammblattErsteller) && (
                    <div className="form-check me-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="filterStatus"
                        id="filterStatusGeloescht"
                        value={"GELOESCHT"}
                        onChange={(e) => options.onChangeHandler(e)}
                        checked={options.value === "GELOESCHT"}
                      />
                      <label className="form-check-label" htmlFor="filterStatusGeloescht">
                        Gelöscht
                      </label>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
