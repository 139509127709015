import { Link } from "react-router-dom";
import { BasicUserInfoResponseType } from "../../../api";
import { MitgliedschaftViewStatsEnum } from "../enum/MitgliedschaftViewStatsEnums";
import IconUser from "../svgs/IconUser";
import IconArrowRight from "../svgs/IconArrowRight";
import { URL_ALLE_ESERVICES, getLink } from "../constants/urls";
import AuthenticatedUserPopup from "./AuthenticatedUserPopup";
import { useEffect } from "react";

interface Props {
  currentView: MitgliedschaftViewStatsEnum;
  userInfo?: BasicUserInfoResponseType;
  kontaktdatenEnabled: boolean;
  loginHandler: () => any;
}

export default function MitgliedschaftsViewHeader(props: Props) {
  const isActive = (state: MitgliedschaftViewStatsEnum) => {
    return props.currentView === state ? "active" : "";
  };

  const callLogin = (evt: any) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
      if (props.loginHandler) {
        props.loginHandler();
      }
    }
  };

  const initNavPanels = () => window.initNavPanels();
  const initOverflowNav = () => window.initOverflowNav();
  const mobileNav = () => window.mobileNav();

  useEffect(() => {
    initNavPanels();
    initOverflowNav();
    mobileNav();
  }, []);

  return (
    <>
      <header data-gtm-block="header">
        <nav className="navbar navbar-expand-lg" data-gtm-block="menu">
          <div className="container-fluid p-0">
            <div className="container d-flex">
              <div className="navbar-brand">
                <Link to="/">
                  <img src="./assets/logo.svg" alt="Logo der Wirtschaftskammern Österreich" />
                </Link>
              </div>
              <div className="meta-nav">
                {props.userInfo && (
                  <button
                    className="btn btn-primary toggle-user"
                    id="user-trigger"
                    type="button"
                    title="Benutzerinformationen"
                    aria-label="Benutzerinformationen"
                    data-bs-toggle="collapse"
                    data-bs-target="#User"
                    data-miu-link="http://wko.at/login?returnTo=xxxx"
                    aria-expanded="false"
                    aria-controls="User"
                  >
                    <IconUser />
                    <span className="d-none d-lg-inline-block">{props.userInfo.authenticatedUserName}</span>
                  </button>
                )}
                {!props.userInfo && (
                  <button
                    className="btn btn-primary toggle-user"
                    id="user-trigger"
                    type="button"
                    title="Anmelden"
                    data-gtm-event="user-login-link"
                    onClick={callLogin}
                  >
                    <IconUser />
                    <span className="d-none d-lg-inline-block">Anmelden</span>
                  </button>
                )}
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
              </button>
              <AuthenticatedUserPopup userInfo={props.userInfo} />
            </div>
            <div className="collapse navbar-collapse border-top" id="navbarSupportedContent">
              <div className="container d-flex">
                <ul className="navbar-nav me-auto" data-gtm-element="menu-main">
                  <li className="nav-item">
                    <Link to="/" className={"nav-link " + isActive(MitgliedschaftViewStatsEnum.HOME)}>
                      Mitgliedschaft
                    </Link>
                  </li>
                  {props.userInfo && (
                    <>
                      {props.userInfo.authenticatedUserMemberId !== "0" && (
                        <>
                          {props.kontaktdatenEnabled && (
                            <li className="nav-item">
                              <Link
                                to="/kontaktdaten"
                                className={"nav-link " + isActive(MitgliedschaftViewStatsEnum.KONTAKTDATEN)}
                              >
                                Kontaktdaten
                              </Link>
                            </li>
                          )}
                          <li className="nav-item">
                            <Link
                              to="/bestaetigung"
                              className={"nav-link " + isActive(MitgliedschaftViewStatsEnum.CREATE_STAMMBLATT)}
                            >
                              Bestätigung
                            </Link>
                          </li>
                        </>
                      )}
                      {(props.userInfo.authenticatedUserMemberId !== "0" || props.userInfo.stammblattErsteller) && (
                        <li className="nav-item">
                          <Link
                            to="/firmenauskunft"
                            className={"nav-link " + isActive(MitgliedschaftViewStatsEnum.STAMMBLATT_SUCHE)}
                          >
                            Firmenauskunft
                          </Link>
                        </li>
                      )}
                      {props.userInfo.authenticatedUserMemberId !== "0" && (
                        <li className="nav-item">
                          <Link
                            to="/gewerbeausuebung"
                            className={"nav-link " + isActive(MitgliedschaftViewStatsEnum.RUHEND_WIEDERBETRIEB_MELDUNG)}
                          >
                            Gewerbeausübung
                          </Link>
                        </li>
                      )}
                      {(props.userInfo.authenticatedUserMemberId !== "0" && props.userInfo.personenbetreuer && (
                        <li className="nav-item">
                          <Link
                            to="/personenbetreuer"
                            className={"nav-link " + isActive(MitgliedschaftViewStatsEnum.PERSONENBETREUER)}
                          >
                            Personenbetreuung
                          </Link>
                        </li>
                      )) ||
                        ""}
                    </>
                  )}
                </ul>
                <ul className="navbar-nav meta-nav" data-gtm-element="menu-main">
                  <li className="nav-item">
                    <a target="_blank" rel="noreferrer" href={getLink(URL_ALLE_ESERVICES)} className="nav-link">
                      Alle eServices&nbsp;
                      <IconArrowRight />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
