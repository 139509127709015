import { PropsViews } from "../model/PropsInterfaces";

export default function MitgliedschaftFooter(props: PropsViews) {
  return (
    <>
      <footer className="footer bg--grey-secondary" data-gtm-block="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 p-3 pe-0 mt-3 border-top text-end">
              <ul className="footer-meta m-0">
                <li>
                  <a
                    href="https://www.wko.at/service/barrierefreiheitserklaerung-mein-wko.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Barrierefreiheit
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.wko.at/service/Offenlegung_Oesterreich.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Offenlegung
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.wko.at/service/datenschutzerklaerung.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Datenschutzerklärung
                  </a>
                </li>
                <li>
                  <a href="/" id="edit-cookiesettings">
                    Cookie-Einstellungen
                  </a>
                </li>
                <li>© 2023 WKO</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
