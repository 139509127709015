import { AxiosPromise } from "axios";
import { StaatenkennungModel, StatistikAustriaApi, StrasseSAModel } from "../../../api";
import { BASE_PATH } from "../../../constants/constants";
import { axiosInstance } from "../../../factory/AxiosFactory";

class StatistikAustriaService {
  private static _instance: StatistikAustriaService;

  private api = new StatistikAustriaApi(undefined, BASE_PATH, axiosInstance);

  private constructor() {}

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getStatistikAustriaStrassen(query:string): AxiosPromise<Array<StrasseSAModel>> {
    return this.api.getStatistikAustriaStrassen(query);
  }

  public getStatistikAustriaLaender(query:string): AxiosPromise<Array<StaatenkennungModel>> {
    return this.api.getStatistikAustriaLaender(query);
  }

}

export const statistikAustriaService = StatistikAustriaService.Instance;
