
export const compareLocaleStringWithPutAlleFirst = (a : string,b : string) => {
  if (a === "Alle") {
    return -1;
  } else if (b === "Alle") {
    return 1;
  } else {
    return a.localeCompare(b)
  }
};
export default compareLocaleStringWithPutAlleFirst;