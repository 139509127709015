import { BasicUserInfoResponseType } from "../../../api";
import { getNameByWKIDNeu } from "../model/Kammern";

interface PropsServiceHotlineInfo {
  hotlineKey?: string;
  userInfo?: BasicUserInfoResponseType;
}

export function ServiceHotlineInfo(props: PropsServiceHotlineInfo) {
  return (
    <div className="content serviceline">
      <h4>Sie haben Fragen?</h4>
      {!!!props.hotlineKey && (
        <p>
          Unsere kostenlose WKO Serviceline unterstützt Sie gerne unter der Nummer 0800 221 221 (Mo.-Fr. 8-20 Uhr, Sa.
          8-12 Uhr) oder per E-Mail an{" "}
          <a target="_blank" rel="noreferrer" href="mailto:office@wko.at">
            office@wko.at
          </a>
          .
        </p>
      )}

      {(props.hotlineKey === "nbwb" || props.hotlineKey === "stammblatt" || props.hotlineKey === "kontaktdaten") && (
        <>
          {!!!props.userInfo?.authenticatedRole?.wirtschaftskammer && (
            <p>
              Unsere kostenlose WKO Serviceline unterstützt Sie gerne unter der Nummer 0800 221 221 (Mo.-Fr. 8-20 Uhr,
              Sa. 8-12 Uhr) oder per E-Mail an{" "}
              <a target="_blank" rel="noreferrer" href="mailto:office@wko.at">
                office@wko.at
              </a>
              .
            </p>
          )}

          {props.userInfo?.authenticatedRole?.wirtschaftskammer && (
            <>
              {getNameByWKIDNeu(props.userInfo?.authenticatedRole?.wirtschaftskammer) === "Salzburg" &&
                props.hotlineKey === "nbwb" && (
                  <p>
                    Kontaktieren Sie Ihre Wirtschaftskammer unter der Nummer{" "}
                    <a target="_blank" rel="noreferrer" className="phone" href="tel:+4366288880">
                      +43 (0)662 8888 0
                    </a>{" "}
                    oder senden Sie uns eine E-Mail an{" "}
                    <a target="_blank" rel="noreferrer" href="mailto:ruhendmeldung@wks.at">
                      ruhendmeldung@wks.at
                    </a>
                    .
                  </p>
                )}

              {getNameByWKIDNeu(props.userInfo?.authenticatedRole?.wirtschaftskammer) === "Salzburg" &&
                (props.hotlineKey === "stammblatt" || props.hotlineKey === "kontaktdaten") && (
                  <p>
                    Kontaktieren Sie Ihre Wirtschaftskammer unter der Nummer{" "}
                    <a target="_blank" rel="noreferrer" className="phone" href="tel:+4366288880">
                      +43 (0)662 8888 0
                    </a>{" "}
                    oder senden Sie uns eine E-Mail an{" "}
                    <a target="_blank" rel="noreferrer" href="mailto:info@wks.at">
                      info@wks.at
                    </a>
                    .
                  </p>
                )}

              {getNameByWKIDNeu(props.userInfo?.authenticatedRole?.wirtschaftskammer) === "Oberösterreich" &&
                props.hotlineKey === "nbwb" && (
                  <p>
                    Kontaktieren Sie Ihre Wirtschaftskammer unter der Nummer{" "}
                    <a target="_blank" rel="noreferrer" className="phone" href="tel:+435909093136">
                      +43 (0)5 90 909-3136
                    </a>{" "}
                    oder senden Sie uns eine E-Mail an{" "}
                    <a target="_blank" rel="noreferrer" href="mailto:nbwb@wkooe.at">
                      nbwb@wkooe.at
                    </a>
                    .
                  </p>
                )}

              {getNameByWKIDNeu(props.userInfo?.authenticatedRole?.wirtschaftskammer) === "Oberösterreich" &&
                props.hotlineKey === "stammblatt" && (
                  <p>
                    Unsere kostenlose WKO Serviceline steht Ihnen unter der Nummer +43 (0)800 221 221 (Mo.-Fr. 8-20 Uhr,
                    Sa. 8-12 Uhr) oder per E-Mail an{" "}
                    <a target="_blank" rel="noreferrer" href="mailto:office@wko.at">
                      office@wko.at
                    </a>{" "}
                    gerne zur Verfügung.
                  </p>
                )}

              {getNameByWKIDNeu(props.userInfo?.authenticatedRole?.wirtschaftskammer) === "Niederösterreich" && (
                <p>
                  Kontaktieren Sie Ihre Wirtschaftskammer unter der Nummer{" "}
                  <a target="_blank" rel="noreferrer" className="phone" href="tel:+43274285113520">
                    +43 (0)2742 851 13520
                  </a>{" "}
                  (Mo-Fr 7:30-16:00 Uhr) oder senden Sie uns eine E-Mail an{" "}
                  <a target="_blank" rel="noreferrer" href="mailto:mds@wknoe.at">
                    mds@wknoe.at
                  </a>
                  .
                </p>
              )}

              {getNameByWKIDNeu(props.userInfo?.authenticatedRole?.wirtschaftskammer) === "Wien" && (
                <p>
                  Kontaktieren Sie Ihre Wirtschaftskammer unter der Nummer{" "}
                  <a target="_blank" rel="noreferrer" className="phone" href="tel:+431514501342">
                    +43 1 51450 1342
                  </a>{" "}
                  oder senden Sie uns eine E-Mail an{" "}
                  <a target="_blank" rel="noreferrer" href="mailto:dm@wkw.at">
                    dm@wkw.at
                  </a>
                  .
                </p>
              )}

              {getNameByWKIDNeu(props.userInfo?.authenticatedRole?.wirtschaftskammer) === "Burgenland" && (
                <p>
                  Kontaktieren Sie Ihre Wirtschaftskammer unter der Nummer{" "}
                  <a target="_blank" rel="noreferrer" className="phone" href="tel:+435909071700">
                    +43 (0)590907 1700
                  </a>{" "}
                  oder senden Sie uns eine E-Mail an{" "}
                  <a target="_blank" rel="noreferrer" href="mailto:umlagen@wkbgld.at">
                    umlagen@wkbgld.at
                  </a>
                  .
                </p>
              )}

              {getNameByWKIDNeu(props.userInfo?.authenticatedRole?.wirtschaftskammer) === "Steiermark" && (
                <p>
                  Kontaktieren Sie Ihre Wirtschaftskammer unter der Nummer{" "}
                  <a target="_blank" rel="noreferrer" className="phone" href="tel:+433166010">
                    +43 (0)316 601 0
                  </a>{" "}
                  oder senden Sie uns eine E-Mail an{" "}
                  <a target="_blank" rel="noreferrer" href="mailto:office@wkstmk.at">
                    office@wkstmk.at
                  </a>
                  .
                </p>
              )}

              {getNameByWKIDNeu(props.userInfo?.authenticatedRole?.wirtschaftskammer) === "Tirol" && (
                <p>
                  Kontaktieren Sie Ihre Wirtschaftskammer unter der Nummer{" "}
                  <a target="_blank" rel="noreferrer" className="phone" href="tel:+435909051342">
                    +43 (0)5 90 90 5-1342
                  </a>{" "}
                  oder senden Sie uns eine E-Mail an{" "}
                  <a target="_blank" rel="noreferrer" href="mailto:mitgliederdaten@wktirol.at">
                    mitgliederdaten@wktirol.at
                  </a>
                  .
                </p>
              )}

              {getNameByWKIDNeu(props.userInfo?.authenticatedRole?.wirtschaftskammer) === "Vorarlberg" && (
                <p>
                  Kontaktieren Sie Ihre Wirtschaftskammer unter der Nummer{" "}
                  <a target="_blank" rel="noreferrer" className="phone" href="tel:+435522305206">
                    +43 (0)5522 305 206
                  </a>{" "}
                  oder senden Sie uns eine E-Mail an{" "}
                  <a target="_blank" rel="noreferrer" href="mailto:mds@wkv.at">
                    mds@wkv.at
                  </a>
                  .
                </p>
              )}

              {getNameByWKIDNeu(props.userInfo?.authenticatedRole?.wirtschaftskammer) === "Kärnten" && (
                <p>
                  Kontaktieren Sie Ihre Wirtschaftskammer unter der Nummer{" "}
                  <a target="_blank" rel="noreferrer" className="phone" href="tel:+43590904485">
                    +43 (0)5 90904 485
                  </a>{" "}
                  oder senden Sie uns eine E-Mail an{" "}
                  <a target="_blank" rel="noreferrer" href="mailto:mitgliederdatenservice@wkk.or.at">
                    mitgliederdatenservice@wkk.or.at
                  </a>
                  .
                </p>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
