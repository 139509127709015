export default function IconHourglass() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.4"
      height="22"
      viewBox="0 0 16.4 22"
      className="icon icon-hourglass"
    >
      <g id="Hourglass-1--Streamline-Ultimate" transform="translate(0.5 0.5)">
        <path
          id="Pfad_2898"
          data-name="Pfad 2898"
          d="M48.682,31.925a7.457,7.457,0,0,0,5.565,6.087,7.457,7.457,0,0,0,5.565-6.087,1.4,1.4,0,0,0-1.392-1.613H50.074a1.4,1.4,0,0,0-1.392,1.613Z"
          transform="translate(-46.547 -27.512)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Pfad_2899"
          data-name="Pfad 2899"
          d="M30.313,6.063h15.4"
          transform="translate(-30.313 -6.063)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Pfad_2900"
          data-name="Pfad 2900"
          d="M50.075,104.7a1.4,1.4,0,0,1-1.392-1.612A7.459,7.459,0,0,1,54.248,97a7.459,7.459,0,0,1,5.565,6.088,1.4,1.4,0,0,1-1.392,1.612Z"
          transform="translate(-46.548 -86.5)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Pfad_2901"
          data-name="Pfad 2901"
          d="M30.313,187.938h15.4"
          transform="translate(-30.313 -166.938)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
