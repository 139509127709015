import React, { useEffect, useState } from "react";
import { MitgliedschaftRequestType, MitgliedschaftResponseType, Standort } from "../../../api";
import { PropsSearchInfoView } from "../model/PropsInterfaces";
import { mitgliedschaftService } from "../service/MitgliedschaftService";
import { StammblattHinweisBestaetigung } from "../view/Hinweise";
import StammblattBerechtigungsauswahl from "../view/StammblattBerechtigungsauswahl";
import { ServiceHotlineInfo } from "../view/InfoComponents";
import { ModalError, ModalSuccessDialog } from "../view/ModalDialogs";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { MitgliedschaftStammblattStatusEnum } from "../enum/MitgliedschaftViewStatsEnums";
import { BundeslaenderResultSelect } from "../form/Selects";
import { Spinner } from "../view/Spinner";
import { BerechtigungsFilterStatus } from "../enum/Berechtigungsstatus";

export default function StammblattView(props: PropsSearchInfoView) {
  const [stammblattBundeslaenderResult, setStammblattBundeslaenderResult] = useState<MitgliedschaftResponseType>();
  const [stammblattStandorteResult, setStammblattStandorteResult] = useState<MitgliedschaftResponseType>();
  const [unfilteredStandorteResult, setUnfilteredStandorteResult] = useState<MitgliedschaftResponseType>();
  const [selectedBundesland, setSelectedBundesland] = useState("all");
  const [selectedStandorte, setSelectedStandorte] = useState<Array<Standort>>([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
  });
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [stammblattStatus, setStammblattStatus] = useState<MitgliedschaftStammblattStatusEnum>(
    MitgliedschaftStammblattStatusEnum.INITIAL
  );
  const [successDialogShow, setSuccessDialogShow] = useState(false);
  const [errorDialogResult, setErrorDialogResult] = useState<Array<string>>();
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorModalTitle, setErrorModalTitle] = useState<string>();
  const [errorDialogShow, setErrorDialogShow] = useState(false);

  const [filterStatus, setFilterStatus] = useState<string | undefined>();

  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    if (props.userInfo?.authenticatedUserMemberId !== "0" || props.userInfo?.stammblattErsteller) {
      const requestParameterBundeslaender: MitgliedschaftRequestType = {
        sucheKammer: props.selectedOrganisation?.wirtschaftskammerIdNeu,
        sucheMGNr: props.selectedOrganisation?.mitgliedsnummer,
      };

      mitgliedschaftService.getStammblattBundeslaender(requestParameterBundeslaender).then((prod) => {
        let data: MitgliedschaftResponseType = prod.data;
        setStammblattBundeslaenderResult({
          ...stammblattBundeslaenderResult,
          ...data,
        });
        loadData(data);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadData();
  }, [selectedBundesland, filterStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = (data?: MitgliedschaftResponseType) => {
    setLoading(true);
    if (data?.kammern || stammblattBundeslaenderResult?.kammern) {
      if (selectedBundesland === "all" && data?.kammern?.length === 1) {
        setSelectedBundesland(data?.kammern ? (data?.kammern[0].wknrNeu ? data?.kammern[0].wknrNeu : "all") : "all");
      } else {
        const requestParameterStandorte: MitgliedschaftRequestType = {
          gewkammernummer: selectedBundesland,
          sucheKammer: props.selectedOrganisation?.wirtschaftskammerIdNeu,
          sucheMGNr: props.selectedOrganisation?.mitgliedsnummer,
          gewFilter: filterStatus ? filterStatus : "",
        };

        mitgliedschaftService.getStammblattStandorte(requestParameterStandorte).then((prod) => {
          let data: MitgliedschaftResponseType = prod.data;
          setStammblattStandorteResult({
            ...stammblattStandorteResult,
            ...data,
          });
          setTotalRecords(data.standorte?.length ? data.standorte?.length : 0);
          if (data.standorte?.length === 1) {
            setSelectedStandorte([data.standorte[0]]);
          } else {
            setSelectedStandorte([]);
          }

          if (!filterStatus) {
            setUnfilteredStandorteResult({
              ...stammblattStandorteResult,
              ...data,
            });
            if (data.hasStatusAktiv && !data.hasStatusRuhend && !data.hasStatusGeloescht) {
              setFilterStatus(BerechtigungsFilterStatus.AKTIV);
            } else if (!data.hasStatusAktiv && data.hasStatusRuhend && !data.hasStatusGeloescht) {
              setFilterStatus(BerechtigungsFilterStatus.RUHEND);
            } else if (
              !data.hasStatusAktiv &&
              !data.hasStatusRuhend &&
              data.hasStatusGeloescht &&
              (props.userInfo?.explizitStammblattErsteller || !props.selectedOrganisation)
            ) {
              setFilterStatus(BerechtigungsFilterStatus.GELOESCHT);
            } else {
              if (props.userInfo?.explizitStammblattErsteller || !props.selectedOrganisation) {
                setFilterStatus(BerechtigungsFilterStatus.ALLE);
              } else {
                setFilterStatus(BerechtigungsFilterStatus.AKTIV_RUHEND);
              }
            }
          }

          setLoading(false);
        });
      }
    } else {
      setLoading(false);
    }
  };

  const onPage = (event: any) => {
    setLazyParams(event);
  };

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderDataTableHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Suche" />
        </span>
      </div>
    );
  };
  const dataTableheader = renderDataTableHeader();

  const changeSelectedBundesland = (e: any) => {
    setFilterStatus(undefined);
    setStammblattStandorteResult(undefined);
    setUnfilteredStandorteResult(undefined);
    setSelectedStandorte([]);
    setSelectedBundesland(e.target.value);
  };

  const changeStammblattStatus = (evt: any, newStatus: MitgliedschaftStammblattStatusEnum) => {
    if (evt?.preventDefault) {
      evt.preventDefault();
    }
    setStammblattStatus(newStatus);
  };

  const createStammblatt = (evt: any) => {
    if (evt?.preventDefault) {
      evt.preventDefault();
    }

    const requestParameterStandorte: MitgliedschaftRequestType = {
      gewStandort: selectedBundesland === "all" ? "" : selectedStandorte.map((s) => s.wkblueId).join(),
      gewKammer:
        selectedBundesland === "all"
          ? stammblattBundeslaenderResult?.kammern?.map((k) => k.wknrNeu).join()
          : selectedBundesland,
      sucheKammer: props.selectedOrganisation?.wirtschaftskammerIdNeu,
      sucheMGNr: props.selectedOrganisation?.mitgliedsnummer,
      recipient: email,
      filterType: filterStatus,
    };

    setLoading(true);
    mitgliedschaftService
      .createStammblatt(requestParameterStandorte)
      .then((prod) => {
        let data: MitgliedschaftResponseType = prod.data;
        if (data?.erfolgreich) {
          window.dataLayer.push({
            event: "eservice-request",
            serviceName: "Mitgliedschaft - Bestätigung erstellt",
          });
          setSuccessDialogShow(true);
        } else {
          if (data.fehler && data.fehler.indexOf("maxBerechtigungen") >= 0) {
            setErrorModalTitle("Bestätigung konnte nicht erstellt werden!");
            setErrorTitle("Für Ihre Auswahl gibt es zu viele Berechtigungen (" + data.totalNrOfSearchResults + ")");
            const messages: string[] = [];
            messages.push(
              "Bitte schränken Sie Ihre Auswahl ein, indem Sie nur einzelne Kammern oder weniger Standorte auswählen."
            );
            setErrorDialogResult(messages);
          }
          setErrorDialogShow(true);
        }
      })
      .catch(() => {
        setErrorDialogShow(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseSuccessDialog = () => {
    setSuccessDialogShow(false);
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogShow(false);
  };

  const handleBerechtigungstypChange = (evt: any) => {
    setFilterStatus(evt.currentTarget.value);
  };

  return (
    <>
      <main data-gtm-block="main">
        <section className="members bg--white" data-gtm-section="Mein WKO - eService Dokumente zertifizieren">
          <div className="container col-lg-7">
            {!props.selectedOrganisation && <h1>Bestätigung</h1>}
            {props.selectedOrganisation && <h1>Firmenauskunft</h1>}

            {((props.userInfo?.authenticatedUserMemberId !== "0" || props.userInfo?.stammblattErsteller) && (
              <>
                {stammblattStatus === MitgliedschaftStammblattStatusEnum.INITIAL && (
                  <div className="content">
                    {!props.selectedOrganisation && (
                      <>
                        <div className="selection checked mb-5">
                          <p className="infotitle">
                            Unternehmen
                            <span className="company">{props.userInfo?.authenticatedUserRoleName}</span>
                          </p>
                        </div>
                      </>
                    )}
                    {props.selectedOrganisation && (
                      <>
                        <div className="selection checked mb-5">
                          <p className="infotitle">
                            Firmenname
                            <span className="company">{props.selectedOrganisation?.bezeichnung}</span>
                          </p>
                        </div>
                      </>
                    )}
                    <h4>Wählen Sie die Standorte aus</h4>
                    <div className="filter input-group d-flex">
                      <div className="element-dropdown pe-5 col-4">
                        <div className="content form-group mb-3">
                          <p className="infotitle">Filtern nach Bundesland</p>
                          <BundeslaenderResultSelect
                            data={stammblattBundeslaenderResult}
                            value={selectedBundesland}
                            onChangeHandler={changeSelectedBundesland}
                            loading={loading}
                          />
                        </div>
                      </div>
                      <StammblattBerechtigungsauswahl
                        data={unfilteredStandorteResult}
                        userInfo={props.userInfo}
                        value={filterStatus ? filterStatus : "ALLE"}
                        onChangeHandler={handleBerechtigungstypChange}
                        searched={!!props.selectedOrganisation}
                        loading={loading}
                      />
                    </div>

                    {selectedBundesland !== "all" && (
                      <DataTable
                        className={"mb-5"}
                        value={stammblattStandorteResult?.standorte}
                        header={dataTableheader}
                        selection={selectedStandorte}
                        onSelectionChange={(e) => setSelectedStandorte(e.value)}
                        dataKey="wkblueId"
                        currentPageReportTemplate="Ergebnisse {first} bis {last} von {totalRecords}"
                        paginator={totalRecords >= 5}
                        totalRecords={totalRecords}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        first={lazyParams.first}
                        onPage={onPage}
                        rows={10}
                        loading={loading}
                        responsiveLayout="scroll"
                        filters={filters}
                        globalFilterFields={["plz", "ort", "strasseHausnummer"]}
                        emptyMessage="Kein Ergebnis für diese Filter!"
                      >
                        <Column selectionMode="multiple" headerStyle={{ width: "6%" }}></Column>
                        <Column field="plz" header="PLZ" style={{ width: "8%" }}></Column>
                        <Column field="ort" header="Ort" style={{ width: "30%" }}></Column>
                        <Column field="strasseHausnummer" header="Strasse" style={{ width: "48%" }}></Column>
                      </DataTable>
                    )}
                    {selectedBundesland === "all" && (
                      <div className="selection checked mb-5">
                        <p className="infotitle">Bestätigung wird für alle Standorte des Unternehmens erstellt!</p>
                      </div>
                    )}
                    <div className="action-button mb-2">
                      {props.selectedOrganisation && (
                        <>
                          <button className="btn btn-secondary" onClick={props.resetOrganisationSelectionHandler}>
                            Zurück
                          </button>
                          &nbsp;
                        </>
                      )}
                      <button
                        className="btn btn-primary"
                        onClick={(e) =>
                          changeStammblattStatus(e, MitgliedschaftStammblattStatusEnum.STANDORTE_GEWAEHLT)
                        }
                        disabled={selectedBundesland !== "all" && selectedStandorte.length < 1}
                      >
                        Weiter
                      </button>
                    </div>
                  </div>
                )}
                {stammblattStatus === MitgliedschaftStammblattStatusEnum.STANDORTE_GEWAEHLT && (
                  <div className="content">
                    {!props.selectedOrganisation && (
                      <div className="selection checked mb-4">
                        <p className="infotitle">
                          Unternehmen
                          <span className="company">{props.userInfo?.authenticatedUserRoleName}</span>
                        </p>
                      </div>
                    )}
                    {props.selectedOrganisation && (
                      <>
                        <div className="selection checked mb-5">
                          <p className="infotitle">
                            Firmenname
                            <span className="company">{props.selectedOrganisation?.bezeichnung}</span>
                          </p>
                        </div>
                      </>
                    )}
                    {selectedBundesland !== "all" && (
                      <div className="selection checked mb-5">
                        <p className="infotitle">Bestätigung wird für die ausgewählten Standorte erstellt</p>
                        <DataTable
                          value={selectedStandorte}
                          currentPageReportTemplate="Ergebnisse {first} bis {last} von {totalRecords}"
                          paginator={selectedStandorte && selectedStandorte.length >= 5}
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          rows={10}
                          responsiveLayout="scroll"
                        >
                          <Column field="plz" header="PLZ" style={{ width: "8%" }}></Column>
                          <Column field="ort" header="Ort" style={{ width: "30%" }}></Column>
                          <Column field="strasseHausnummer" header="Strasse" style={{ width: "48%" }}></Column>
                        </DataTable>
                      </div>
                    )}
                    {selectedBundesland === "all" && (
                      <div className="selection checked mb-5">
                        <p className="infotitle">Bestätigung wird für alle Standorte des Unternehmens erstellt!</p>
                      </div>
                    )}
                    <div className="element-input floating-label mb-5">
                      <div className="element-email form group mb-3 col-6">
                        <div className="form-floating">
                          <input
                            type="email"
                            className="form-control"
                            id="input_email"
                            placeholder="Eingabefeld E-Mail"
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                          />
                          <label htmlFor="input_email">Zusätzlich an E-Mail senden</label>
                          <div className="invalid-feedback">Bitte eine korrekte E-Mail Adresse eingeben</div>
                          <div className="valid-feedback">Erfolgreiche Eingabe</div>
                        </div>
                      </div>
                    </div>
                    {loading && <Spinner />}
                    {!loading && (
                      <div className="action-button mb-2">
                        <a
                          href="/"
                          className="btn btn-secondary"
                          onClick={(e) => changeStammblattStatus(e, MitgliedschaftStammblattStatusEnum.INITIAL)}
                        >
                          Zurück
                        </a>
                        <a
                          href="/"
                          className="btn btn-primary"
                          onClick={(e) => createStammblatt(e)}
                          aria-controls="success"
                        >
                          Bestätigung erstellen
                        </a>
                      </div>
                    )}
                    <StammblattHinweisBestaetigung />
                  </div>
                )}
              </>
            )) || (
              <>
                <p>Sie besitzen leider keine Berechtigung für dieses Service mit der ausgewählten Benutzerrolle.</p>
                <p>&nbsp;</p>
              </>
            )}

            <ServiceHotlineInfo hotlineKey="stammblatt" userInfo={props.userInfo} />
          </div>
        </section>
      </main>
      <ModalSuccessDialog show={successDialogShow} onHide={handleCloseSuccessDialog} okButtonPath={props.currentPath} />
      <ModalError
        show={errorDialogShow}
        onHide={handleCloseErrorDialog}
        modalTitle={errorModalTitle}
        errorTitle={errorTitle}
        errorMessages={errorDialogResult}
      />
    </>
  );
}
