import { useEffect, useState } from "react";
import { BasicUserInfoResponseType } from "../../../api";
import MitgliedschaftHome from "../component/Home";
import { MitgliedschaftViewStatsEnum } from "../enum/MitgliedschaftViewStatsEnums";
import MitgliedschaftsViewHeader from "../component/Header";
import MitgliedschaftFooter from "../component/Footer";
import StammblattView from "../component/Stammblatt";
import StammblattSucheView from "../component/StammblattSuche";
import RuhendWiederbetriebMeldungView from "../component/RuhendWiederbetriebMeldung";
import PersonenbetreuerSucheView from "../component/PersonenbetreuerSuche";
import { userService } from "../service/UserService";
import { cookieService } from "../service/CookieService";
import { SessionTimer } from "../utils/SessionTimer";
import { LoadingScreen } from "../view/LoadingScreen";
import { PropsViews } from "../model/PropsInterfaces";
import Cookies from "universal-cookie";
import { URL_STEWARD_LOGOUT, getLink } from "../constants/urls";
import KontaktdatenView from "../component/Kontaktdaten";
import { mitgliedschaftService } from "../service/MitgliedschaftService";

const forwardToWKISLogin = () => {
  window.location.href = "/MeinWKOREST/b/login/mitgliedschaft";
};

export default function MitgliedschaftController(options: PropsViews) {
  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(true);

  const [userInfo, setUserInfo] = useState<BasicUserInfoResponseType>();
  const [kontaktdatenEnabled, setKontaktdatenEnabled] = useState<boolean>(false);
  const [shouldLogin, setShouldLogin] = useState(false);
  
  useEffect(() => {
    if (!userInfo && (initializing || shouldLogin)) {
      const cookies = new Cookies(null, { path: "" });
      let loginState = cookies.get("loginState");
      if (shouldLogin) {
        cookies.set("loginState", "firstTry");
        forwardToWKISLogin();
      } else if (loginState === "retry") {
        cookies.set("loginState", "retried");
        forwardToWKISLogin();
      } else {
        setInitializing(false);
        setLoading(true);
        cookieService
          .getCookies()
          .then((res) => {
            if (res.data && res.data !== "noCookie") {
              userService
                .getBasicUserInfo()
                .then((res) => {
                  let data: BasicUserInfoResponseType = res.data;
                  cookies.remove("loginState");
                  cookies.remove("loginError");

                  mitgliedschaftService
                  .isKontaktdatenEnabled()
                  .then((result) => {
                    setKontaktdatenEnabled(Boolean(result.data.data).valueOf());
                  })
                .finally(() => {
                  setUserInfo({ ...data });
                  setLoading(false);
                });

                })
                .catch(() => {
                  if (!cookies.get("loginError")) {
                    cookies.set("loginError", "noUserData");
                    setShouldLogin(true);
                  }
                })
                .finally(() => {
                  setLoading(false);
                });
            } else {
              if (loginState === "firstTry") {
                cookies.set("loginState","retry");
                window.location.href = getLink(URL_STEWARD_LOGOUT);
              } else {
                cookies.remove("loginState");
                setLoading(false);
              }
            }
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  }, [userInfo, shouldLogin, initializing]);

  const callLogin = () => {
    if (!userInfo) {
      if (shouldLogin) {
        // back button from wkis clicked
        forwardToWKISLogin();
      }
      setShouldLogin(true);
    }
  };

  useEffect(() => {
    if (userInfo) {
      const sessionTimer = new SessionTimer(userInfo);
      sessionTimer.startCounter();
    }
  }, [userInfo]);

  useEffect(() => {
    (document.getElementsByTagName("body")[0] as HTMLElement).classList.remove(
      "supervision"
    );
    if (
      options.currentView === MitgliedschaftViewStatsEnum.STAMMBLATT_SUCHE ||
      options.currentView === MitgliedschaftViewStatsEnum.PERSONENBETREUER
    ) {
      (document.getElementsByTagName("body")[0] as HTMLElement).classList.add(
        "supervision"
      );
    }
  }, [options.currentView]);

  return (
    <>
      {loading && (
        <>
          <LoadingScreen />
        </>
      )}
      {!loading && (
        <>
          <MitgliedschaftsViewHeader
            currentView={options.currentView!}
            userInfo={userInfo}
            kontaktdatenEnabled={kontaktdatenEnabled}
            loginHandler={callLogin}
          />
          {options.currentView === MitgliedschaftViewStatsEnum.HOME && (
            <>
              <MitgliedschaftHome
                loginHandler={callLogin}
                kontaktdatenEnabled={kontaktdatenEnabled}
                userInfo={userInfo}
              />
            </>
          )}
          {userInfo && (
            <>
              {kontaktdatenEnabled && options.currentView ===
                MitgliedschaftViewStatsEnum.KONTAKTDATEN && (
                <>
                  <KontaktdatenView
                    userInfo={userInfo}
                    currentPath={options.currentPath}
                  />
                </>
              )}
              {options.currentView ===
                MitgliedschaftViewStatsEnum.CREATE_STAMMBLATT && (
                <>
                  <StammblattView
                    userInfo={userInfo}
                    currentPath={options.currentPath}
                  />
                </>
              )}
              {options.currentView ===
                MitgliedschaftViewStatsEnum.STAMMBLATT_SUCHE && (
                <>
                  <StammblattSucheView
                    userInfo={userInfo}
                    currentPath={options.currentPath}
                  />
                </>
              )}
              {options.currentView ===
                MitgliedschaftViewStatsEnum.RUHEND_WIEDERBETRIEB_MELDUNG && (
                <>
                  <RuhendWiederbetriebMeldungView
                    userInfo={userInfo}
                    currentPath={options.currentPath}
                  />
                </>
              )}
              {options.currentView ===
                MitgliedschaftViewStatsEnum.PERSONENBETREUER && (
                <>
                  <PersonenbetreuerSucheView
                    userInfo={userInfo}
                    currentPath={options.currentPath}
                  />
                </>
              )}
            </>
          )}
          <MitgliedschaftFooter currentView={options.currentView} />
        </>
      )}
    </>
  );
}
