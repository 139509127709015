import { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;

}

const IconHinweis = (options:Props) =>  {
  return (
          <svg
            className="icon icon-hinweis"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            style={options.style}
          >
            <g
              id="streamline-icon-light-bulb-shine_80x80"
              data-name="streamline-icon-light-bulb-shine@80x80"
              transform="translate(-0.756 -0.756)"
            >
              <line
                id="Linie_65"
                data-name="Linie 65"
                x2="6"
                transform="translate(21.756 44.756)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <path
                id="Pfad_232"
                data-name="Pfad 232"
                d="M42.577,31.258a11.289,11.289,0,1,0-14.111,10.89v3.221H34.11V42.148A11.263,11.263,0,0,0,42.577,31.258Z"
                transform="translate(-6.532 -6.502)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <line
                id="Linie_66"
                data-name="Linie 66"
                y1="3"
                transform="translate(24.756 4.756)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <line
                id="Linie_67"
                data-name="Linie 67"
                x2="3"
                transform="translate(41.756 21.756)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <line
                id="Linie_68"
                data-name="Linie 68"
                x2="3"
                transform="translate(4.756 24.756)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <line
                id="Linie_69"
                data-name="Linie 69"
                x2="1.996"
                y2="1.996"
                transform="translate(36.73 35.319)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <line
                id="Linie_70"
                data-name="Linie 70"
                x2="1.996"
                y2="1.996"
                transform="translate(10.786 9.375)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <line
                id="Linie_71"
                data-name="Linie 71"
                y1="1.996"
                x2="1.996"
                transform="translate(36.73 9.375)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <line
                id="Linie_72"
                data-name="Linie 72"
                y1="1.996"
                x2="1.996"
                transform="translate(10.786 35.319)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
          </svg>
  );
}
export default IconHinweis;