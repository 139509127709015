import React, { useEffect, useRef, useState } from "react";
import { FileAttachment, MitgliedschaftRequestType, MitgliedschaftResponseType } from "../../../api";
import { PropsSearchInfoView, Berechtigungsdata } from "../model/PropsInterfaces";
import { mitgliedschaftService } from "../service/MitgliedschaftService";
import { ServiceHotlineInfo } from "../view/InfoComponents";
import { Hinweis, NBWBHinweisBerechtigungen, NBWBHinweisBestaetigung } from "../view/Hinweise";
import { ModalError, ModalSuccessDialog, ModalAccept } from "../view/ModalDialogs";
import IconPlus from "../svgs/IconPlus";
import IconMinus from "../svgs/IconMinus";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { FilterMatchMode } from "primereact/api";
import { MESSAGE_TEXTS, MitgliedschaftNBWBStatusEnum } from "../enum/MitgliedschaftViewStatsEnums";
import { Berechtigungsstatus } from "../enum/Berechtigungsstatus";
import moment from "moment";
import compareLocaleStringWithPutAlleFirst from "../../../utils/CompareUtils";
import { ALL_SELECTION_OPTION } from "../../../constants/constants";
import { FachgruppeBerufszweigSelect } from "../form/Selects";
import { NichtbetriebWiederbetriebMeldung } from "../../../api/model/nichtbetrieb-wiederbetrieb-meldung";
import { Spinner } from "react-bootstrap";
import IconTooltip from "../svgs/IconTooltip";

import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { FileUpload } from "primereact/fileupload";
import { emptyTemplate, headerTemplate, itemTemplate } from "../view/FileUpload";
import { DeleteModal } from "../view/ModalDialogs";
import IconHinweis from "../svgs/IconHinweis";

export default function RuhendWiederbetriebMeldungView(props: PropsSearchInfoView) {
  const fileUploadDialog = useRef<FileUpload>(null);
  const [allBerechtigungen, setAllBerechtigungen] = useState<Array<Berechtigungsdata>>();
  const [filteredBerechtigungen, setFilteredBerechtigungen] = useState<Array<Berechtigungsdata>>();
  const [selectedBerechtigungen, setSelectedBerechtigungen] = useState<Array<Berechtigungsdata>>([]);
  const [validBerechtigungsSelection, setValidBerechtigungsSelection] = useState(true);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [nbwbViewStatus, setNbwbViewStatus] = useState<MitgliedschaftNBWBStatusEnum>(
    MitgliedschaftNBWBStatusEnum.INITIAL
  );
  const [successDialogShow, setSuccessDialogShow] = useState(false);
  const [errorDialogShow, setErrorDialogShow] = useState(false);
  const [errorDialog3WeeksPersBetrShow, setErrorDialog3WeeksPersBetrShow] = useState(false);
  const [accepted3WeeksPersBetr, setAccepted3WeeksPersBetr] = useState(false);
  const [errorDialog18MonthShow, setErrorDialog18MonthShow] = useState(false);
  const [errorDialog180DaysFutureShow, setErrorDialog180DaysFutureShow] = useState(false);
  const [errorDialogResult, setErrorDialogResult] = useState<Array<string>>();
  const [errorDialogFileTooSmallShow, setErrorDialogFileTooSmallShow] = useState(false);
  const [zustimmung, setZustimmung] = useState(false);

  const [allBerufszweige, setAllBerufszweige] = useState<Array<string>>([]);
  const [selectableFachgruppen, setSelectableFachgruppen] = useState<Array<string>>([]);
  const [selectableBerufszweige, setSelectableBerufszweige] = useState<Array<string>>([]);
  const [selectedFachgruppe, setSelectedFachgruppe] = useState("");
  const [selectedBerufszweig, setSelectedBerufszweig] = useState("");

  const [showTooltipFachgruppe, setShowTooltipFachgruppe] = useState(false);
  const [showTooltipBerufszweig, setShowTooltipBerufszweig] = useState(false);
  const tooltipFachgruppeClickTarget = useRef(null);
  const tooltipBerufszweigClickTarget = useRef(null);

  const [deleteModalPersonenbetreuer, setDeleteModalPersonenbetreuer] = useState(false);

  const [vollmacht, setVollmacht] = useState<FileAttachment>();
  const [fehler, setFehler] = useState<string[]>();

  const [countMeldungen, setCountMeldungen] = useState<number>(1);
  interface Meldungsdatum {
    index: number;
    dateRuhend: Date | Date[] | undefined;
    dateWiederaufnahme: Date | Date[] | undefined;
    ruhendValidated: boolean;
    wiederaufnahmeValidated: boolean;
  }
  const meldungenArray: Array<Meldungsdatum> = [
    {
      index: 0,
      dateRuhend: undefined,
      dateWiederaufnahme: undefined,
      ruhendValidated: true,
      wiederaufnahmeValidated: true,
    },
    {
      index: 1,
      dateRuhend: undefined,
      dateWiederaufnahme: undefined,
      ruhendValidated: true,
      wiederaufnahmeValidated: true,
    },
    {
      index: 2,
      dateRuhend: undefined,
      dateWiederaufnahme: undefined,
      ruhendValidated: true,
      wiederaufnahmeValidated: true,
    },
    {
      index: 3,
      dateRuhend: undefined,
      dateWiederaufnahme: undefined,
      ruhendValidated: true,
      wiederaufnahmeValidated: true,
    },
    {
      index: 4,
      dateRuhend: undefined,
      dateWiederaufnahme: undefined,
      ruhendValidated: true,
      wiederaufnahmeValidated: true,
    },
  ];
  const [meldungen, setMeldungen] = useState<Array<Meldungsdatum>>(meldungenArray);

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accepted3WeeksPersBetr) {
      createBerechtigungsChange();
    }
  }, [accepted3WeeksPersBetr]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = () => {
    if (props.userInfo?.authenticatedUserMemberId !== "0") {
      setLoading(true);

      const requestParameter: MitgliedschaftRequestType = {
        sucheKammer: props.selectedOrganisation?.wirtschaftskammerIdNeu,
        sucheMGNr: props.selectedOrganisation?.mitgliedsnummer,
      };

      mitgliedschaftService.searchBerechtigungen(requestParameter).then((prod) => {
        let data: MitgliedschaftResponseType = prod.data;
        prepareBerechtigungen(data.berechtigungen);
        setLoading(false);
      });
    }
  };

  const prepareBerechtigungen = (berechtigungen: Array<Berechtigungsdata> | undefined) => {
    const fachgruppen: Array<string> = [ALL_SELECTION_OPTION];
    const berufszweige: Array<string> = [];

    moment.locale("de");
    const now = moment().valueOf();

    berechtigungen?.forEach((berechtigung) => {
      var lastHistorieNB = 0;
      var lastHistorieWB = 0;
      if (berechtigung?.nichtbetriebeWiederbetriebe?.length) {
        lastHistorieNB =
          +berechtigung.nichtbetriebeWiederbetriebe[berechtigung.nichtbetriebeWiederbetriebe.length - 1].nichtbetrieb;
        lastHistorieWB =
          +berechtigung?.nichtbetriebeWiederbetriebe[berechtigung.nichtbetriebeWiederbetriebe.length - 1].wiederbetrieb;
      }

      berechtigung.hasNBWBinZukunft = false;
      berechtigung.eingabeStatus = berechtigung.status;
      berechtigung.letzteMeldung = berechtigung.datumAktivInaktivSeit;
      berechtigung.statusText =
        berechtigung.status + " seit " + moment(berechtigung.datumAktivInaktivSeit).format("DD.MM.YYYY");

      if (lastHistorieNB > now || lastHistorieWB > now) {
        berechtigung.hasNBWBinZukunft = true;
        if (lastHistorieWB > 0) {
          berechtigung.eingabeStatus = Berechtigungsstatus.AKTIV;
          berechtigung.letzteMeldung = "" + lastHistorieWB;
        } else {
          berechtigung.eingabeStatus = Berechtigungsstatus.RUHEND;
          berechtigung.letzteMeldung = "" + lastHistorieNB;
        }
      }

      berechtigung.fachgruppen = [];
      berechtigung.berufszweige = [];
      if (berechtigung.spfgbz) {
        berechtigung.spfgbz.forEach(function (fachgruppeBerufszweig) {
          var fg = fachgruppeBerufszweig.fgNummer + " - " + fachgruppeBerufszweig.fgBezeichung;
          var bz =
            fachgruppeBerufszweig.spFgBzNummer +
            " - " +
            fachgruppeBerufszweig.fgBezeichung +
            " / " +
            fachgruppeBerufszweig.bgBezeichung;
          berechtigung.fachgruppen?.push(fg);
          berechtigung.berufszweige?.push(bz);
          if (fachgruppen.indexOf(fg) < 0) {
            fachgruppen.push(fg);
          }
          if (berufszweige.indexOf(bz) < 0) {
            berufszweige.push(bz);
          }
        });
      }
    });

    fachgruppen.sort(compareLocaleStringWithPutAlleFirst);
    berufszweige.sort();
    setSelectableFachgruppen(fachgruppen);
    setSelectedFachgruppe(ALL_SELECTION_OPTION);
    setAllBerufszweige(berufszweige);
    if (fachgruppen.length > 2) {
      setSelectableBerufszweige([ALL_SELECTION_OPTION]);
    } else {
      let selectableBZ: Array<string> = [ALL_SELECTION_OPTION];
      berufszweige.map((bz) => selectableBZ.push(bz));
      setSelectableBerufszweige(selectableBZ);
    }
    setAllBerechtigungen(berechtigungen);
    setFilteredBerechtigungen(berechtigungen);
  };

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderDataTableHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Suche" />
        </span>
      </div>
    );
  };
  const dataTableheader = renderDataTableHeader();

  const changeNBWBViewStatus = (evt: any, newStatus: MitgliedschaftNBWBStatusEnum) => {
    if (evt?.preventDefault) {
      evt.preventDefault();
    }
    setNbwbViewStatus(newStatus);
  };

  /* init fachgruppe / berufszweig auswahl */
  const handleFachgruppeChange = (event: any) => {
    const berechtigungen: Array<Berechtigungsdata> = [];
    const selectedFachgruppe = event.currentTarget.value;
    const selectedFgNummer = selectedFachgruppe.substring(0, selectedFachgruppe.indexOf(" - "));
    setSelectedFachgruppe(selectedFachgruppe);
    handleSelectionChange([]);

    const currentBerufszweigeForFg: Array<string> = [];
    currentBerufszweigeForFg.push(ALL_SELECTION_OPTION);
    if (selectedFachgruppe !== ALL_SELECTION_OPTION) {
      allBerufszweige.forEach(function (bz) {
        if (bz.indexOf(selectedFgNummer) === 0) {
          currentBerufszweigeForFg.push(bz);
        }
      });
    }
    setSelectableBerufszweige(currentBerufszweigeForFg);
    setSelectedBerufszweig(ALL_SELECTION_OPTION);

    allBerechtigungen?.forEach(function (berechtigung) {
      if (
        selectedFachgruppe === ALL_SELECTION_OPTION ||
        (berechtigung.fachgruppen && berechtigung.fachgruppen.indexOf(selectedFachgruppe) >= 0)
      ) {
        berechtigungen.push(berechtigung);
      }
    });

    setFilteredBerechtigungen(berechtigungen);
  };
  const handleBerufszweigChange = (event: any) => {
    const berechtigungen: Array<Berechtigungsdata> = [];
    var selectedBerufszweig = event.currentTarget.value;
    setSelectedBerufszweig(selectedBerufszweig);
    handleSelectionChange([]);

    allBerechtigungen?.forEach(function (berechtigung) {
      if (
        selectedBerufszweig === ALL_SELECTION_OPTION &&
        (selectedFachgruppe === ALL_SELECTION_OPTION ||
          (berechtigung.fachgruppen && berechtigung.fachgruppen.indexOf(selectedFachgruppe) >= 0))
      ) {
        berechtigungen.push(berechtigung);
      } else if (berechtigung.berufszweige && berechtigung.berufszweige.indexOf(selectedBerufszweig) >= 0) {
        berechtigungen.push(berechtigung);
      }
    });
    setFilteredBerechtigungen(berechtigungen);
  };

  const createBerechtigungsChange = () => {
    setLoading(true);
    const meldungsdaten = new Array<NichtbetriebWiederbetriebMeldung>();

    selectedBerechtigungen?.forEach(function (berechtigung) {
      let firstRuhend = moment(meldungen.at(0)?.dateRuhend as Date).valueOf();
      let firstWiederaufnahme = moment(meldungen.at(0)?.dateWiederaufnahme as Date).valueOf();
      let letzteMeldung = moment(berechtigung.letzteMeldung).valueOf();
      let wirksamkeitsdatum = moment(berechtigung.wirksamkeitsdatum).valueOf();

      if (wirksamkeitsdatum === letzteMeldung && firstRuhend < letzteMeldung) {
        setMeldungen(
          meldungen.map((meldung) => (meldung.index === 0 ? { ...meldung, ruhendValidated: false } : { ...meldung }))
        );
      }
      if (wirksamkeitsdatum < letzteMeldung && firstWiederaufnahme <= letzteMeldung) {
        setMeldungen(
          meldungen.map((meldung) =>
            meldung.index === 0 ? { ...meldung, wiederaufnahmeValidated: false } : { ...meldung }
          )
        );
      }
    });

    const currentFehler: string[] = [];
    if (validateCalenderEntries()) {
      meldungen.forEach(function (meldung, index) {
        if (index < countMeldungen && (meldung.dateRuhend || meldung.dateWiederaufnahme)) {
          const meldungsdatum: NichtbetriebWiederbetriebMeldung = {
            nichtbetrieb: meldung.dateRuhend ? "" + moment(meldung.dateRuhend as Date).valueOf() : undefined,
            wiederbetrieb: meldung.dateWiederaufnahme
              ? "" + moment(meldung.dateWiederaufnahme as Date).valueOf()
              : undefined,
          };
          meldungsdaten.push(meldungsdatum);

          if (
            meldung.dateRuhend &&
            moment(meldung.dateRuhend as Date).valueOf() < moment().subtract(3, "week").valueOf()
          ) {
            if (moment(meldung.dateRuhend as Date).valueOf() < moment().subtract(18, "month").valueOf()) {
              // error18MonthsLimit = true;
              currentFehler.push(MESSAGE_TEXTS.NBWB_18_MONTH_ERROR);
              setErrorDialog18MonthShow(true);
            } else {
              // 3 weeks accept dialog for personenbetreuer
              if (!!!accepted3WeeksPersBetr) {
                currentFehler.push(MESSAGE_TEXTS.NBWB_3_WEEKS_MESSAGE_PERS_BETR);
                setErrorDialog3WeeksPersBetrShow(true);
              }
            }
          }

          if (
            meldung.dateWiederaufnahme &&
            moment(meldung.dateWiederaufnahme as Date).valueOf() < moment().subtract(3, "week").valueOf()
          ) {
            if (moment(meldung.dateWiederaufnahme as Date).valueOf() < moment().subtract(18, "month").valueOf()) {
              // error18MonthsLimit = true;
              currentFehler.push(MESSAGE_TEXTS.NBWB_18_MONTH_ERROR);
              setErrorDialog18MonthShow(true);
            } else {
              // 3 weeks accept dialog for personenbetreuer
              if (!!!accepted3WeeksPersBetr) {
                currentFehler.push(MESSAGE_TEXTS.NBWB_3_WEEKS_MESSAGE_PERS_BETR);
                setErrorDialog3WeeksPersBetrShow(true);
              }
            }
          }

          if (
            meldung.dateRuhend &&
            moment(meldung.dateRuhend as Date).valueOf() > moment().add(180, "days").valueOf()
          ) {
            setMeldungen(
              meldungen.map((meldung) =>
                meldung.index === index ? { ...meldung, ruhendValidated: false } : { ...meldung }
              )
            );
            currentFehler.push(MESSAGE_TEXTS.NBWB_180_DAYS_FUTURE_ERROR);
            setErrorDialog180DaysFutureShow(true);
          }

          if (
            meldung.dateWiederaufnahme &&
            moment(meldung.dateWiederaufnahme as Date).valueOf() > moment().add(180, "days").valueOf()
          ) {
            setMeldungen(
              meldungen.map((meldung) =>
                meldung.index === index ? { ...meldung, wiederaufnahmeValidated: false } : { ...meldung }
              )
            );
            currentFehler.push(MESSAGE_TEXTS.NBWB_180_DAYS_FUTURE_ERROR);
            setErrorDialog180DaysFutureShow(true);
          }
        }
      });

      if (currentFehler.length > 0) {
        //setFehler(currentFehler);
        setLoading(false);
      } else {
        selectedBerechtigungen?.forEach(function (berechtigung) {
          berechtigung.meldungen = meldungsdaten;
        });

        const requestParameter: MitgliedschaftRequestType = {
          meldungen: selectedBerechtigungen,
        };

        if (props.selectedOrganisation) {
          requestParameter.personenBetreuer = true;
          requestParameter.betreuerKammerid = props.selectedOrganisation.wirtschaftskammerIdNeu;
          requestParameter.betreuerMitgliedid = props.selectedOrganisation.mitgliedsnummer;
          requestParameter.betreuerFirmenname = props.selectedOrganisation.bezeichnung;
          requestParameter.betreuerGeburtsdatum =
            "" + moment(props.selectedOrganisation.geburtsdatum, "DD.MM.YYYY").valueOf();
          requestParameter.betreuerGisaZahl = props.selectedOrganisation.gisaZahl;
          requestParameter.vollmacht = vollmacht;
        }

        mitgliedschaftService
          .saveMeldungen(requestParameter)
          .then((result) => {
            let data: MitgliedschaftResponseType = result.data;
            if (data?.erfolgreich) {
              window.dataLayer.push({
                event: "eservice-request",
                serviceName: "Mitgliedschaft - Status geändert",
              });
              setAccepted3WeeksPersBetr(false);
              setSuccessDialogShow(true);
            } else {
              setErrorDialogResult(data.fehler);
              setErrorDialogShow(true);
            }
          })
          .catch((error) => {
            setErrorDialogShow(true);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setLoading(false);
    }
  };

  const resetMeldungen = () => {
    setMeldungen(meldungenArray);
    setCountMeldungen(1);
  };

  const handleMeldungRuhendUpdate = (index: number, date: Date | Date[] | undefined) => {
    setFehler(undefined);
    setMeldungen(
      meldungen.map((meldung) =>
        meldung.index === index ? { ...meldung, dateRuhend: date, ruhendValidated: true } : { ...meldung }
      )
    );
  };

  const handleMeldungWiederbetriebUpdate = (index: number, date: Date | Date[] | undefined) => {
    setFehler(undefined);
    setMeldungen(
      meldungen.map((meldung) =>
        meldung.index === index
          ? {
              ...meldung,
              dateWiederaufnahme: date,
              wiederaufnahmeValidated: true,
            }
          : { ...meldung }
      )
    );
  };

  const validateCalenderEntries = (): boolean => {
    let valid = true;
    let lastDate = 0;
    meldungen.forEach((meldung, index) => {
      if (valid && index < countMeldungen) {
        const ruhendTimestamp = meldung.dateRuhend ? moment(meldung.dateRuhend as Date).valueOf() : undefined;
        const wiederaufnahmeTimestamp = meldung.dateWiederaufnahme
          ? moment(meldung.dateWiederaufnahme as Date).valueOf()
          : undefined;

        if (ruhendTimestamp) {
          if (lastDate >= ruhendTimestamp) {
            setMeldungen(
              meldungen.map((meldung) =>
                meldung.index === index ? { ...meldung, ruhendValidated: false } : { ...meldung }
              )
            );
            valid = false;
            return false;
          } else {
            lastDate = ruhendTimestamp;
          }
        } else {
          if (index !== 0 || selectedBerechtigungen[0].status === Berechtigungsstatus.AKTIV) {
            setMeldungen(
              meldungen.map((meldung) =>
                meldung.index === index ? { ...meldung, ruhendValidated: false } : { ...meldung }
              )
            );
            valid = false;
            return false;
          }
        }

        if (wiederaufnahmeTimestamp) {
          if (lastDate >= wiederaufnahmeTimestamp) {
            setMeldungen(
              meldungen.map((meldung) =>
                meldung.index === index ? { ...meldung, wiederaufnahmeValidated: false } : { ...meldung }
              )
            );
            valid = false;
            return false;
          } else {
            lastDate = wiederaufnahmeTimestamp;
          }
        } else {
          if (
            index !== countMeldungen - 1 ||
            (index === 0 && selectedBerechtigungen[0].status === Berechtigungsstatus.RUHEND)
          ) {
            setMeldungen(
              meldungen.map((meldung) =>
                meldung.index === index ? { ...meldung, wiederaufnahmeValidated: false } : { ...meldung }
              )
            );
            valid = false;
            return false;
          }
        }
      }

      return false;
    });
    return valid;
  };

  var onRemove = () => {};

  const handleFileChange = async (evt: any) => {
    const file = evt.files[0];
    if (file.size > 20000) {
      const reader = new FileReader();
      reader.onloadend = function (e: any) {
        const base64data = e.target.result;
        setVollmacht({
          name: file.name,
          mimetype: file.type,
          data: base64data! as string,
        });
      };
      reader.readAsDataURL(file);
    } else {
      setVollmacht(undefined);
      fileUploadDialog.current?.clear();
      setErrorDialogFileTooSmallShow(true);
    }
  };

  const itemTemplateCreate = (file: any, props: any) => {
    onRemove = props.onRemove;
    return itemTemplate(file, "", () => {
      setDeleteModalPersonenbetreuer(true);
    });
  };

  const resetCreateView = (fireCallback: boolean) => {
    if (fireCallback) {
      setVollmacht(undefined);
      if (onRemove) {
        onRemove();
      }
    }
  };

  const callModalCallback = (fireCallback: boolean) => {
    setDeleteModalPersonenbetreuer(false);
    resetCreateView(fireCallback);
  };

  const isSelectable = (data: any) => {
    let isSelectable = true;
    if (data.gesperrtFuerNBWB) {
      isSelectable = false;
    }
    return isSelectable;
  };

  const rowClassName = (data: any) => {
    return isSelectable(data) ? "" : "p-disabled";
  };

  const statusBodyTemplate = (rowData: any) => {
    return <span>{rowData.statusText}</span>;
  };

  const gewerbeberechtigungBodyTemplate = (rowData: any) => {
    if (isSelectable(rowData)) {
      return <span>{rowData.berechtWortlaut}</span>;
    } else {
      return (
        <>
          <div>{rowData.berechtWortlaut}</div>
          <div>&nbsp;</div>
          <div>
            <strong>
              Achtung: Für dieses Gewerbe kann die Ruhendmeldung/Wiederaufnahme nicht online eingebracht werden.
            </strong>
          </div>
        </>
      );
    }
  };

  const handleSelectionChange = (selectedElements: Array<Berechtigungsdata>) => {
    let selectionType: string | undefined;
    if (selectedElements.length === 0) {
      setValidBerechtigungsSelection(true);
    } else {
      selectedElements.forEach((element) => {
        if (!selectionType) {
          selectionType = element.status;
        } else {
          if (selectionType !== element.status) {
            selectionType = "failed";
            return;
          }
        }
      });
      setValidBerechtigungsSelection(selectionType !== "failed");
    }
    setSelectedBerechtigungen(selectedElements);
  };

  return (
    <>
      <main data-gtm-block="main">
        <section className="members bg--white" data-gtm-section="Mein WKO - eService Dokumente zertifizieren">
          <ModalSuccessDialog
            show={successDialogShow}
            onHide={() => {
              setSuccessDialogShow(false);
            }}
            okButtonPath={props.currentPath}
          />
          <div className="container col-lg-7">
            {!props.selectedOrganisation && <h1>Gewerbeausübung</h1>}
            {props.selectedOrganisation && (
              <div className="members-header">
                <div className="headline">
                  <div className="headline-bc">
                    <h1>Personenbetreuung</h1>
                    <h3 className="subtitle">Gewerbeausübung für Personenbetreuung</h3>
                  </div>
                </div>
              </div>
            )}

            {(props.userInfo?.authenticatedUserMemberId !== "0" && (
              <>
                <div className="content">
                  {!props.selectedOrganisation && (
                    <>
                      <div className="selection checked mb-5">
                        <p className="infotitle">
                          Firmenname
                          <span className="company">{props.userInfo?.authenticatedUserRoleName}</span>
                        </p>
                      </div>
                    </>
                  )}
                  {props.selectedOrganisation && (
                    <>
                      <div className="selection checked mb-5">
                        <p className="infotitle">
                          Firmenname
                          <span className="company">{props.selectedOrganisation?.bezeichnung}</span>
                        </p>
                      </div>
                    </>
                  )}
                  {nbwbViewStatus === MitgliedschaftNBWBStatusEnum.INITIAL && (
                    <div className="selection checked mb-5">
                      <h4>Welche Gewerbeberechtigung wollen Sie ruhend melden oder wiederaufnehmen?</h4>
                      {(selectableFachgruppen.length > 2 || selectableBerufszweige.length > 2) && (
                        <div className="filter input-group d-flex">
                          {selectableFachgruppen.length > 2 && (
                            <div className="element-dropdown pe-5 col-4">
                              <div className="content form-group mb-3">
                                <p className="infotitle">
                                  Filtern nach Fachgruppe
                                  <span
                                    className="icon-info"
                                    ref={tooltipFachgruppeClickTarget}
                                    onClick={() => setShowTooltipFachgruppe(!showTooltipFachgruppe)}
                                  >
                                    <IconTooltip />
                                  </span>
                                </p>
                                <Overlay
                                  target={tooltipFachgruppeClickTarget.current}
                                  show={showTooltipFachgruppe}
                                  placement="top"
                                >
                                  {(props) => (
                                    <Tooltip id="fachgruppeTooltip" {...props}>
                                      Filtern Sie das Ergebnis zuerst nach Fachgruppe und anschließend nach Berufszweig.
                                      Es werden nur jene angezeigt, bei welchen Sie auch Berechtigungen haben.
                                    </Tooltip>
                                  )}
                                </Overlay>
                                <FachgruppeBerufszweigSelect
                                  label="Filtern nach Bundesland"
                                  value={selectedFachgruppe}
                                  values={selectableFachgruppen}
                                  onChangeHandler={handleFachgruppeChange}
                                />
                              </div>
                            </div>
                          )}
                          {selectableBerufszweige.length > 2 && (
                            <div className="element-dropdown pe-5 col-4">
                              <div className="content form-group mb-3">
                                <p className="infotitle">
                                  Filtern nach Berufszweig
                                  <span
                                    className="icon-info"
                                    ref={tooltipBerufszweigClickTarget}
                                    onClick={() => setShowTooltipBerufszweig(!showTooltipBerufszweig)}
                                  >
                                    <IconTooltip />
                                  </span>
                                </p>
                                <Overlay
                                  target={tooltipBerufszweigClickTarget.current}
                                  show={showTooltipBerufszweig}
                                  placement="top"
                                >
                                  {(props) => (
                                    <Tooltip id="berufszweigTooltip" {...props}>
                                      Filtern Sie das Ergebnis zuerst nach Fachgruppe und anschließend nach Berufszweig.
                                      Es werden nur jene angezeigt, bei welchen Sie auch Berechtigungen haben.
                                    </Tooltip>
                                  )}
                                </Overlay>
                                <FachgruppeBerufszweigSelect
                                  label="Filtern nach Berufszweig"
                                  value={selectedBerufszweig}
                                  values={selectableBerufszweige}
                                  onChangeHandler={handleBerufszweigChange}
                                />
                                <div className="invalid-feedback">Bitte eine korrekte E-Mail Adresse eingeben</div>
                                <div className="valid-feedback">Erfolgreiche Eingabe</div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      <DataTable
                        className={"mb-5"}
                        value={filteredBerechtigungen}
                        header={dataTableheader}
                        selection={selectedBerechtigungen}
                        onSelectionChange={(e) => {
                          handleSelectionChange(e.value);
                        }}
                        isDataSelectable={(event) => {
                          const data = event.data;
                          return isSelectable(data);
                        }}
                        rowClassName={rowClassName}
                        dataKey="berechtigungsnummer"
                        paginator={filteredBerechtigungen && filteredBerechtigungen.length >= 5}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Ergebnisse {first} bis {last} von {totalRecords}"
                        rows={5}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        loading={loading}
                        responsiveLayout="scroll"
                        filters={filters}
                        globalFilterFields={["berechtWortlaut", "gisazahl", "standortAdresse", "statusText"]}
                        emptyMessage="Kein Ergebnis für diese Filter!"
                      >
                        <Column selectionMode="multiple"></Column>
                        <Column header="Status" body={statusBodyTemplate}></Column>
                        <Column field="standortAdresse" header="Standort"></Column>
                        <Column header="Gewerbeberechtigung" body={gewerbeberechtigungBodyTemplate}></Column>
                        <Column field="gisazahl" header="GISA-Zahl"></Column>
                      </DataTable>
                      <div className="action-button mb-2">
                        {props.selectedOrganisation && props.resetOrganisationSelectionHandler && (
                          <button
                            className="btn btn-secondary"
                            onClick={() => {
                              resetMeldungen();
                              props.resetOrganisationSelectionHandler!();
                            }}
                          >
                            Zurück
                          </button>
                        )}
                        <button
                          className="btn btn-primary"
                          onClick={(e) => changeNBWBViewStatus(e, MitgliedschaftNBWBStatusEnum.BERECHTIGUNGEN_GEWAEHLT)}
                          disabled={selectedBerechtigungen.length < 1 || !validBerechtigungsSelection}
                        >
                          Weiter
                        </button>
                      </div>
                      {!validBerechtigungsSelection && (
                        <Hinweis
                          message="Es können nur Meldungen für Gewerbeberechtigungen erstellt werden, die denselben Status (aktiv/ruhend) aufweisen."
                          marginBottom={true}
                        />
                      )}
                      <NBWBHinweisBerechtigungen />
                    </div>
                  )}
                  {nbwbViewStatus === MitgliedschaftNBWBStatusEnum.BERECHTIGUNGEN_GEWAEHLT && (
                    <>
                      <div className="selection checked mb-5">
                        <p className="infotitle">Ausgewählte Gewerbeberechtigungen</p>
                        <DataTable
                          dataKey="berechtigungsnummer"
                          value={selectedBerechtigungen}
                          currentPageReportTemplate="Ergebnisse {first} bis {last} von {totalRecords}"
                          paginator={selectedBerechtigungen && selectedBerechtigungen.length >= 5}
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                          rows={10}
                          responsiveLayout="scroll"
                        >
                          <Column field="statusText" header="Status"></Column>
                          <Column field="standortAdresse" header="Standort"></Column>
                          <Column field="berechtWortlaut" header="Gewerbeberechtigung"></Column>
                          <Column field="gisazahl" header="GISA-Zahl"></Column>
                        </DataTable>
                      </div>

                      {props.selectedOrganisation && (
                        <>
                          <div className="upload col-8">
                            <p className="infotitle">Hochladen einer Vollmacht</p>
                            <FileUpload
                              ref={fileUploadDialog}
                              name="vollmacht-upload"
                              accept=".pdf,.doc,.jpg,.jpeg,.png,.docx"
                              maxFileSize={20971520}
                              itemTemplate={itemTemplateCreate}
                              emptyTemplate={emptyTemplate}
                              onSelect={handleFileChange}
                              headerTemplate={headerTemplate}
                            />
                            <DeleteModal
                              modalId="deleteModalCreate"
                              show={deleteModalPersonenbetreuer}
                              resetViewHandler={callModalCallback}
                              onHide={() => callModalCallback(false)}
                            />
                          </div>
                        </>
                      )}

                      <div>
                        {meldungen.map(
                          (meldung, index) =>
                            index < countMeldungen && (
                              <div key={index} className="element-combi d-flex">
                                <div className="element-date form-group mb-3 col-4 me-4">
                                  <div
                                    className="content"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label
                                      htmlFor={"ruhend-meldung-" + index}
                                      className="control-label infotitle"
                                      style={{ paddingRight: "10px" }}
                                    >
                                      Gewerbe ruhend melden ab:
                                    </label>
                                    <Calendar
                                      id={"ruhend-meldung-" + index}
                                      value={meldung.dateRuhend}
                                      onChange={(e) => handleMeldungRuhendUpdate(index, e.value)}
                                      showButtonBar
                                      showIcon
                                      disabled={
                                        index === 0 && selectedBerechtigungen[0].status === Berechtigungsstatus.RUHEND
                                      }
                                    />
                                    {!meldung.ruhendValidated && (
                                      <div className="invalid-feedback" style={{ display: "block" }}>
                                        Bitte ein korrektes Datum eingeben
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="element-date form-group mb-3 col-4 me-4">
                                  <div
                                    className="content"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label
                                      htmlFor={"wiederaufnahme-meldung-" + index}
                                      className="control-label infotitle"
                                      style={{ paddingRight: "10px" }}
                                    >
                                      Wiederaufnahme ab:
                                    </label>
                                    <Calendar
                                      id={"wiederaufnahme-meldung-" + index}
                                      value={meldung.dateWiederaufnahme}
                                      onChange={(e) => handleMeldungWiederbetriebUpdate(index, e.value)}
                                      showButtonBar
                                      showIcon
                                    />
                                    {!meldung.wiederaufnahmeValidated && (
                                      <div className="invalid-feedback" style={{ display: "block" }}>
                                        Bitte ein korrektes Datum eingeben
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {index === countMeldungen - 1 && (
                                  <>
                                    {index !== 0 && (
                                      <div className="minus">
                                        <button
                                          className="btn btn-link"
                                          type="button"
                                          title="Zeitraum entfernen"
                                          aria-label="Zeitraum entfernen"
                                          onClick={() =>
                                            setCountMeldungen(countMeldungen === 1 ? 1 : countMeldungen - 1)
                                          }
                                        >
                                          Zeitraum entfernen <IconMinus />
                                        </button>
                                      </div>
                                    )}
                                    {index !== 4 && (
                                      <div className="plus">
                                        <button
                                          className="btn btn-link"
                                          type="button"
                                          title="Zeitraum hinzufügen"
                                          aria-label="Zeitraum hinzufügen"
                                          onClick={() =>
                                            setCountMeldungen(countMeldungen === 5 ? 5 : countMeldungen + 1)
                                          }
                                        >
                                          Zeitraum hinzufügen <IconPlus />
                                        </button>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            )
                        )}
                      </div>
                      {fehler && (
                        <>
                          <div
                            className={"alert alert-box alert-info border-0 rounded-0 bg--peach-secondary"}
                            role="alert"
                            data-gtm-box="Hinweis"
                          >
                            <div className="alert-info-bulb">
                              <IconHinweis />
                              <span>Fehler</span>
                            </div>
                            <div className="alert-info-content">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: fehler[0] || "",
                                }}
                              ></p>
                            </div>
                          </div>
                        </>
                      )}
                      <NBWBHinweisBestaetigung />
                      <div className="element-datenschutz floating-label">
                        <div className="content form-group mb-3">
                          <div className="datenschutz form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="datenschutz"
                              checked={zustimmung}
                              required={true}
                              onChange={(e) => setZustimmung(e.currentTarget.checked)}
                            />
                            <label className="form-check-label" htmlFor="datenschutz">
                              Ich bin die Gewerbeinhaberin/der Gewerbeinhaber bzw. das vertretungsbefugte Organ und habe
                              die{" "}
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.wko.at/service/wirtschaftsrecht-gewerberecht/ruhendmeldung-wiederaufnahme-gewerbeausuebung.html?_ga=2.122745293.737405137.1680768879-1621555869.1680768879&_gl=1*18gocl7*_ga*MTYyMTU1NTg2OS4xNjgwNzY4ODc5*_ga_4YHGVSN5S4*MTY4MDc2ODg3OS4xLjEuMTY4MDc2OTQ0Mi40MS4wLjA"
                              >
                                rechtlichen Hinweise
                              </a>{" "}
                              und die{" "}
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.wko.at/service/datenschutzerklaerung.html?_ga=2.30940537.737405137.1680768879-1621555869.1680768879&_gl=1*1opbr85*_ga*MTYyMTU1NTg2OS4xNjgwNzY4ODc5*_ga_4YHGVSN5S4*MTY4MDc2ODg3OS4xLjEuMTY4MDc2OTg1Ny42MC4wLjA"
                              >
                                Informationen zum Datenschutz
                              </a>{" "}
                              gelesen und akzeptiert. <title>eService - Mitgliedschaft - WKO.at</title>
                            </label>
                            <div className="invalid-feedback">Bitte bestätigen Sie mit einem Häckchen</div>
                          </div>
                        </div>
                      </div>
                      {loading && <Spinner />}
                      {!loading && (
                        <div className="action-button mb-2">
                          <button
                            className="btn btn-secondary"
                            onClick={(e) => {
                              resetMeldungen();
                              changeNBWBViewStatus(e, MitgliedschaftNBWBStatusEnum.INITIAL);
                            }}
                          >
                            Zurück
                          </button>
                          <button
                            className="btn btn-primary"
                            aria-controls="success"
                            onClick={() => createBerechtigungsChange()}
                            disabled={!zustimmung || (props.selectedOrganisation && !vollmacht)}
                          >
                            Abschicken
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            )) || (
              <>
                <p>Sie besitzen leider keine Berechtigung für dieses Service mit der ausgewählten Benutzerrolle.</p>
                <p>&nbsp;</p>
              </>
            )}

            <ServiceHotlineInfo hotlineKey="nbwb" userInfo={props.userInfo} />
          </div>
        </section>
      </main>
      <ModalError
        show={errorDialogShow}
        onHide={() => {
          setErrorDialogShow(false);
        }}
        errorTitle={"Bei der Übermittlung der Daten ist ein Fehler aufgetreten."}
        errorMessages={errorDialogResult}
      />
      <ModalError
        show={errorDialog180DaysFutureShow}
        onHide={() => {
          setErrorDialog180DaysFutureShow(false);
        }}
        modalTitle={"Ungültiger Zeitraum"}
        errorMessages={[MESSAGE_TEXTS.NBWB_180_DAYS_FUTURE_ERROR]}
      />
      <ModalError
        show={errorDialog18MonthShow}
        onHide={() => {
          setErrorDialog18MonthShow(false);
        }}
        modalTitle={"Ungültiger Zeitraum"}
        errorMessages={[MESSAGE_TEXTS.NBWB_18_MONTH_ERROR]}
      />
      <ModalAccept
        show={errorDialog3WeeksPersBetrShow}
        onHide={() => {
          setErrorDialog3WeeksPersBetrShow(false);
        }}
        viewHandler={(accepted: boolean) => {
          setErrorDialog3WeeksPersBetrShow(false);
          if (accepted) {
            setAccepted3WeeksPersBetr(true);
          }
        }}
        modalTitle={"Ungültiger Zeitraum"}
        errorMessages={[MESSAGE_TEXTS.NBWB_3_WEEKS_MESSAGE_PERS_BETR]}
      />
      <ModalError
        show={errorDialogFileTooSmallShow}
        onHide={() => {
          setErrorDialogFileTooSmallShow(false);
        }}
        errorTitle={MESSAGE_TEXTS.NBWB_FILE_SIZE}
        errorMessages={[]}
      />
    </>
  );
}
