export default function IconMitglied() {
  return (
    <svg
      className="icon icon-mitglied"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 201 201"
    >
      <g
        id="streamlinehq-technology-contact-access-check-technology-40"
        transform="translate(0.5 0.5)"
      >
        <path
          id="Pfad_2877"
          data-name="Pfad 2877"
          d="M184.424,76.26V12.995A12.245,12.245,0,0,0,172.179.75H12.995A12.245,12.245,0,0,0,.75,12.995V123.2a12.245,12.245,0,0,0,12.245,12.245H74.927"
          transform="translate(-0.75 -0.75)"
          fill="none"
          stroke="#ee9a89"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          id="Ellipse_33"
          data-name="Ellipse 33"
          cx="19.314"
          cy="19.314"
          r="19.314"
          transform="translate(37.829 22.449)"
          fill="none"
          stroke="#ee9a89"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Pfad_2878"
          data-name="Pfad 2878"
          d="M47.955,42.024H3.694A33.11,33.11,0,0,1,59.367,17.787"
          transform="translate(20.339 57.683)"
          fill="none"
          stroke="#ee9a89"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Linie_109"
          data-name="Linie 109"
          x2="36.735"
          transform="translate(110.204 30.612)"
          fill="none"
          stroke="#ee9a89"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Linie_110"
          data-name="Linie 110"
          x2="48.98"
          transform="translate(110.204 55.102)"
          fill="none"
          stroke="#ee9a89"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Pfad_2891"
          data-name="Pfad 2891"
          d="M44.924,3.662a5.321,5.321,0,0,1,9.489,0L65.448,25.884a5.278,5.278,0,0,0,3.972,2.862l24.735,3.569A5.267,5.267,0,0,1,98.4,35.852a5.158,5.158,0,0,1-1.317,5.321L79.127,58.585a5.18,5.18,0,0,0-1.5,4.581l4.233,24.54A5.169,5.169,0,0,1,79.747,92.8a5.332,5.332,0,0,1-5.561.37L52.139,81.656a5.289,5.289,0,0,0-4.941,0L25.183,93.169a5.332,5.332,0,0,1-5.561-.37,5.169,5.169,0,0,1-2.111-5.093l4.233-24.54a5.18,5.18,0,0,0-1.5-4.581L2.33,41.174a5.158,5.158,0,0,1-1.317-5.321,5.256,5.256,0,0,1,4.244-3.537l24.725-3.569a5.278,5.278,0,0,0,3.907-2.862Z"
          transform="translate(101.343 81.508)"
          fill="none"
          stroke="#ee9a89"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
