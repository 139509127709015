export default function IconFAZEmail() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="Gruppe_2012" data-name="Gruppe 2012" transform="translate(-24 0.248)">
        <rect
          id="Rechteck_169"
          data-name="Rechteck 169"
          width="20"
          height="20"
          transform="translate(24 -0.248)"
          fill="none"
        />
        <path
          id="envelope"
          d="M74.384,439.416a1.469,1.469,0,0,0-.882.342,1.605,1.605,0,0,0-.517,1.063c-.01.092-.009,8.087,0,8.21a1.4,1.4,0,0,0,.6,1.094,1.724,1.724,0,0,0,.758.278c.091.013,13.192.013,13.3,0a1.41,1.41,0,0,0,1.058-.627,1.733,1.733,0,0,0,.265-.78c.011-.117.005-8.205-.006-8.282a1.405,1.405,0,0,0-.6-1.013,1.725,1.725,0,0,0-.748-.278c-.041-.007-1.367-.009-6.6-.009-3.6,0-6.588,0-6.635,0m.6,1.064,1.913,1.317,1.992,1.371c.233.162,1.478,1.017,1.517,1.043a1.062,1.062,0,0,0,.663.165c.048,0,.108-.012.134-.018a1.175,1.175,0,0,0,.349-.143c.033-.021,1.089-.747,1.48-1.017l2.036-1.4,1.913-1.317s-2.693-.006-6-.006-6,0-6,.006m-.933,4.593c0,3.7,0,3.949.013,3.984a.341.341,0,0,0,.2.228.831.831,0,0,0,.124.045l.061.015H81c6.288,0,6.564,0,6.607-.012a.333.333,0,0,0,.153-.08.5.5,0,0,0,.133-.268c.012-.06.013-.175.011-3.959l0-3.9-1.814,1.249c-1,.687-1.813,1.252-1.811,1.255s.468.436,1.036.96a9.165,9.165,0,0,1,1.112,1.066.585.585,0,0,1,.054.311.538.538,0,0,1-.358.44.545.545,0,0,1-.437-.046,16.065,16.065,0,0,1-1.25-1.136l-1.063-.981-.031.023c-.062.045-1.152.793-1.216.835a2.134,2.134,0,0,1-2.309-.006c-.07-.046-1.14-.78-1.207-.829l-.031-.023-1.1,1.016-1.134,1.045a.522.522,0,0,1-.408.123.481.481,0,0,1-.252-.1.53.53,0,0,1-.214-.49.475.475,0,0,1,.1-.254c.021-.028.391-.375,1.066-1,.568-.525,1.034-.957,1.036-.961s-.649-.455-3.457-2.388l-.172-.118,0,3.947"
          transform="translate(-46.977 -434.66)"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
