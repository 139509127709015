export default function IconSearch() {
  return (
    <svg
      className="icon icon-search"
      id="icon-search"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <g
        id="streamline-icon-search_32x32"
        data-name="streamline-icon-search@32x32"
        transform="translate(6.764 6.765)"
      >
        <circle
          id="Ellipse_16"
          data-name="Ellipse 16"
          cx="8.589"
          cy="8.589"
          r="8.589"
          transform="translate(-2.764 3.954) rotate(-23.025)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Linie_48"
          data-name="Linie 48"
          x2="6.662"
          y2="6.663"
          transform="translate(14.574 14.573)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
