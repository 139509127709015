import IconHourglass from "../svgs/IconHourglass";
import IconFAZEmail from "../svgs/IconFAZEmail";
import { URL_NACHRICHTEN, getLink } from "../constants/urls";

export const unchangedMessage = (
  <div
    className="container col-lg-7"
    style={{
      background: "#FCF2F5",
      boxShadow: "0px 3px 6px #00000029",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1rem",
    }}
  >
    <div
      style={{
        fontSize: "16px",
        fontWeight: "700",
        textAlign: "center",
        letterSpacing: "0px",
        color: "#BB0D3B",
        opacity: "1",
      }}
    >
      Die angegebenen Daten sind gleich mit den bestehenden Daten. Bitte ändern Sie zumindest einen Wert.
    </div>
  </div>
);

export const successMessage = (
  <>
    <p>&nbsp;</p>
    <div
      className="container col-lg-7"
      style={{
        background: "#E7F1EB",
        boxShadow: "0px 3px 6px #00000029",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem",
      }}
    >
      <div
        style={{
          fontSize: "16px",
          fontWeight: "700",
          textAlign: "center",
          letterSpacing: "0px",
          color: "#167441",
          opacity: "1",
        }}
      >
        Wir haben Ihre Änderung erhalten! Außerhalb der Geschäftszeiten kann die Bearbeitungszeit einige Zeit in Anspruch nehmen.
      </div>
    </div>
  </>
);
export const errorMessage = (
  <>
    <p>&nbsp;</p>
    <div
      className="container col-lg-7"
      style={{
        background: "#FCF2F5",
        boxShadow: "0px 3px 6px #00000029",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem",
      }}
    >
      <div
        style={{
          fontSize: "16px",
          fontWeight: "700",
          textAlign: "center",
          letterSpacing: "0px",
          color: "#BB0D3B",
          opacity: "1",
        }}
      >
        Es ist zu einem Fehler gekommen. Bitte laden Sie die Seite neu oder versuchen Sie es später erneut.
      </div>
    </div>
  </>
);

export const changeRequestSentText = (
  <p>
    <IconHourglass></IconHourglass>
    <span className="ms-3">
      <strong style={{ position: "relative", top: "3px" }}>Übernahme der Daten in Bearbeitung&nbsp;-&nbsp;</strong>
      <strong style={{ position: "relative", top: "3px", textDecoration: "underline" }}>
        <a href={getLink(URL_NACHRICHTEN)} target="_blank" rel="noreferrer">
          Mein Postfach
        </a>
      </strong>
    </span>
    <span className="ms-3" style={{ marginTop: "2px" }}>
      <IconFAZEmail></IconFAZEmail>
    </span>
  </p>
);
