export default function IconArrowRight() {
  return (
    <svg
      className="icon icon-arrow-right"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 21"
    >
      <g
        id="streamline-icon-arrow-right-1_32x32_1_"
        transform="translate(1.667 5)"
      >
        <path
          id="Pfad_360"
          d="M7.333,1l3.92,3.92a.274.274,0,0,1,0,.388l-3.92,3.92"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        ></path>
      </g>
    </svg>
  );
}
