export default function IconTooltip() {
  return (
    <svg
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      stroke="black"
      viewBox="0 0 21 21"
    >
      <g
        id="Gruppe_2101"
        data-name="Gruppe 2101"
        transform="translate(-70 -350)"
      >
        <g
          id="streamlinehq-information-circle-interface-essential-48"
          transform="translate(72 352)"
        >
          <path
            id="Pfad_579"
            data-name="Pfad 579"
            d="M13.335,15.036h-.567A1.134,1.134,0,0,1,11.634,13.9V11.067a.567.567,0,0,0-.567-.567H10.5"
            transform="translate(-3.134 -3.135)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Pfad_580"
            data-name="Pfad 580"
            d="M11.534,7.317a.284.284,0,1,1,0-.567"
            transform="translate(-3.317 -2.217)"
            fill="none"
            strokeWidth="1"
          />
          <path
            id="Pfad_581"
            data-name="Pfad 581"
            d="M11.625,7.317a.284.284,0,1,0,0-.567"
            transform="translate(-3.408 -2.217)"
            fill="none"
            strokeWidth="1"
          />
          <path
            id="Pfad_582"
            data-name="Pfad 582"
            d="M9.25,17.75a8.5,8.5,0,1,0-8.5-8.5A8.5,8.5,0,0,0,9.25,17.75Z"
            transform="translate(-0.75 -0.75)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
}
