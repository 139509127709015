export default function IconFAZMobil() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="Gruppe_4474" data-name="Gruppe 4474" transform="translate(-12 -263)">
        <rect
          id="Rechteck_169"
          data-name="Rechteck 169"
          width="20"
          height="20"
          transform="translate(12 263)"
          fill="none"
        />
        <path
          id="mobile-phone"
          d="M515.149,73.459a2.52,2.52,0,0,0-2.214,1.635,2.642,2.642,0,0,0-.141.566c-.011.07-.012.382-.015,4.838,0,5.506,0,6.642.016,6.756a2.515,2.515,0,0,0,.225.764,2.438,2.438,0,0,0,.523.729,2.491,2.491,0,0,0,1.474.7c.093.012.238.012,2.76.012s2.667,0,2.76-.012a2.52,2.52,0,0,0,.719-.191,2.492,2.492,0,0,0,.807-.559,2.384,2.384,0,0,0,.445-.625,2.486,2.486,0,0,0,.252-.817c.017-.112.02-1.251.016-6.751,0-4.529,0-4.764-.016-4.841a2.565,2.565,0,0,0-.393-1.064,2.523,2.523,0,0,0-1.51-1.07,2.754,2.754,0,0,0-.312-.057c-.078-.011-.288-.012-2.72-.012-1.449,0-2.653,0-2.675,0m-.051,1.074a1.44,1.44,0,0,0-.828.411,1.342,1.342,0,0,0-.285.4,1.456,1.456,0,0,0-.142.5c-.005.052-.007,1.422-.007,4.486v4.412h7.881V80.33c0-3.064,0-4.435-.007-4.486a1.47,1.47,0,0,0-.514-.983,1.445,1.445,0,0,0-.573-.295,15.123,15.123,0,0,0-2.856-.044c-2.187,0-2.61,0-2.67.01M513.837,86.4c0,.368,0,.622.007.668a1.47,1.47,0,0,0,.514.983,1.446,1.446,0,0,0,.573.295,90.876,90.876,0,0,0,5.694,0,1.46,1.46,0,0,0,1.018-.953,1.6,1.6,0,0,0,.068-.325c0-.046.007-.3.007-.668v-.594h-7.881V86.4"
          transform="translate(-495.777 191.543)"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
