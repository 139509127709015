/* tslint:disable */
/* eslint-disable */
/**
 * MeinWKO - REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { StaatenkennungModel } from '../model';
// @ts-ignore
import { StrasseSAModel } from '../model';
/**
 * StatistikAustriaApi - axios parameter creator
 * @export
 */
export const StatistikAustriaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatistikAustriaLaender: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('getStatistikAustriaLaender', 'query', query)
            const localVarPath = `/v1/statistikAustria/laender`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatistikAustriaStrassen: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('getStatistikAustriaStrassen', 'query', query)
            const localVarPath = `/v1/statistikAustria/strassen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatistikAustriaApi - functional programming interface
 * @export
 */
export const StatistikAustriaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatistikAustriaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatistikAustriaLaender(query: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StaatenkennungModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatistikAustriaLaender(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatistikAustriaStrassen(query: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StrasseSAModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatistikAustriaStrassen(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatistikAustriaApi - factory interface
 * @export
 */
export const StatistikAustriaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatistikAustriaApiFp(configuration)
    return {
        /**
         * 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatistikAustriaLaender(query: string, options?: any): AxiosPromise<Array<StaatenkennungModel>> {
            return localVarFp.getStatistikAustriaLaender(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatistikAustriaStrassen(query: string, options?: any): AxiosPromise<Array<StrasseSAModel>> {
            return localVarFp.getStatistikAustriaStrassen(query, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatistikAustriaApi - object-oriented interface
 * @export
 * @class StatistikAustriaApi
 * @extends {BaseAPI}
 */
export class StatistikAustriaApi extends BaseAPI {
    /**
     * 
     * @param {string} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatistikAustriaApi
     */
    public getStatistikAustriaLaender(query: string, options?: AxiosRequestConfig) {
        return StatistikAustriaApiFp(this.configuration).getStatistikAustriaLaender(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatistikAustriaApi
     */
    public getStatistikAustriaStrassen(query: string, options?: AxiosRequestConfig) {
        return StatistikAustriaApiFp(this.configuration).getStatistikAustriaStrassen(query, options).then((request) => request(this.axios, this.basePath));
    }
}
