export default function IconUser() {
  return (
    <svg
      className="icon icon-user"
      xmlns="http://www.w3.org/2000/svg"
      id="icon-user"
      viewBox="0 0 32 32"
    >
      <g
        id="streamline-icon-single-neutral-actions_24x24"
        transform="translate(4.525 4.606)"
      >
        <circle
          id="Ellipse_15"
          cx="4.967"
          cy="4.967"
          r="4.967"
          transform="translate(6.508 0.75)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></circle>
        <path
          id="Pfad_113"
          d="M2.25,22.725a9.225,9.225,0,0,1,18.45,0"
          transform="translate(0 -0.686)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </g>
    </svg>
  );
}
