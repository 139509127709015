import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Mitgliedschaft from "./apps/Mitgliedschaft/Mitgliedschaft";
import { MitgliedschaftPathEnum, MitgliedschaftViewStatsEnum } from "./apps/Mitgliedschaft/enum/MitgliedschaftViewStatsEnums";

declare global {
  interface Window {
    SG: any;
    dataLayer: any;
    initNavPanels: () => void;
    initOverflowNav: () => void;
    mobileNav: () => void;
  }
}

var SG: any = window.SG || {};
SG.react = SG.react || {};

SG.react.mitgliedschaft = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <Suspense fallback={<h1>Loading...</h1>}>
          <Routes>
            <Route
              path={MitgliedschaftPathEnum.HOME}
              element={
                <Mitgliedschaft
                  currentView={MitgliedschaftViewStatsEnum.HOME}
                  currentPath={MitgliedschaftPathEnum.HOME}
                />
              }
            />
            <Route
              path={MitgliedschaftPathEnum.KONTAKTDATEN}
              element={
                <Mitgliedschaft
                  currentView={MitgliedschaftViewStatsEnum.KONTAKTDATEN}
                  currentPath={MitgliedschaftPathEnum.KONTAKTDATEN}
                />
              }
            />
            <Route
              path={MitgliedschaftPathEnum.CREATE_STAMMBLATT}
              element={
                <Mitgliedschaft
                  currentView={MitgliedschaftViewStatsEnum.CREATE_STAMMBLATT}
                  currentPath={MitgliedschaftPathEnum.CREATE_STAMMBLATT}
                />
              }
            />
            <Route
              path={MitgliedschaftPathEnum.STAMMBLATT_SUCHE}
              element={
                <Mitgliedschaft
                  currentView={MitgliedschaftViewStatsEnum.STAMMBLATT_SUCHE}
                  currentPath={MitgliedschaftPathEnum.STAMMBLATT_SUCHE}
                />
              }
            />
            <Route
              path={MitgliedschaftPathEnum.RUHEND_WIEDERBETRIEB_MELDUNG}
              element={
                <Mitgliedschaft
                  currentView={
                    MitgliedschaftViewStatsEnum.RUHEND_WIEDERBETRIEB_MELDUNG
                  }
                  currentPath={MitgliedschaftPathEnum.RUHEND_WIEDERBETRIEB_MELDUNG}
                />
              }
            />
            <Route
              path={MitgliedschaftPathEnum.PERSONENBETREUER}
              element={
                <Mitgliedschaft
                  currentView={MitgliedschaftViewStatsEnum.PERSONENBETREUER}
                  currentPath={MitgliedschaftPathEnum.PERSONENBETREUER}
                />
              }
            />
          </Routes>
        </Suspense>
      </Router>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

SG.react.mitgliedschaft();
