export default function IconEnvelope() {
  return (
    <svg
      className="icon icon-user"
      xmlns="http://www.w3.org/2000/svg"
      id="icon-envelope"
      viewBox="0 0 21 21"
    >
      <g id="streamline-icon-envelope_21x21" transform="translate(1.844 1.229)">
        <rect
          id="Rechteck_217"
          width="16"
          height="10.667"
          rx="1.313"
          transform="translate(0.656 3.938)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></rect>
        <line
          id="Linie_63"
          x2="2.375"
          y2="2.195"
          transform="translate(11.547 7.572)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></line>
        <line
          id="Linie_64"
          x1="2.375"
          y2="2.195"
          transform="translate(3.391 7.572)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></line>
        <path
          id="Pfad_226"
          d="M15.98,4.387,9.422,8.328a1.863,1.863,0,0,1-1.885,0L.98,4.387"
          transform="translate(0.183 -0.13)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </g>
    </svg>
  );
}
