import { ServiceHotlineInfo } from "../view/InfoComponents";
import IconSearch from "../svgs/IconSearch";

import { MitgliedschaftRequestType, MitgliedschaftResponseType } from "../../../api";
import { mitgliedschaftService } from "../service/MitgliedschaftService";
import { useEffect, useRef, useState } from "react";
import moment from "moment";

import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Organisation } from "../../../api/model/organisation";
import RuhendWiederbetriebMeldungView from "./RuhendWiederbetriebMeldung";
import { PropsViews } from "../model/PropsInterfaces";
import { BundeslaenderResultSelect } from "../form/Selects";
import { alleBundeslaender } from "../model/Kammern";
import { Spinner } from "../view/Spinner";
import { getNameByWKIDNeu } from "../model/Kammern";

export default function PersonenbetreuerSucheView(props: PropsViews) {
  const [bundeslaenderAuswahl, setBundeslaenderAuswahl] = useState<MitgliedschaftResponseType>();
  const [sucheOrganisationenResult, setSucheOrganisationenResult] = useState<MitgliedschaftResponseType>();
  const [letzteSucheOrganisationenResult, setLetzteSucheOrganisationenResult] = useState<MitgliedschaftResponseType>();
  const [selectedBundesland, setSelectedBundesland] = useState(
    props.userInfo?.authenticatedRole?.wirtschaftskammer || ""
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalRecordsLetzteSuche, setTotalRecordsLetzteSuche] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
  });
  const [selectedOrganisation, setSelectedOrganisation] = useState<Organisation | undefined>(undefined);
  const toast = useRef<Toast>(null);
  const [acceptTermsChecked, setAcceptTermsChecked] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(
    window.sessionStorage.getItem("personenbetreuerAcceptedTerms") === "true"
  );

  moment.locale("de");
  const now = moment().format("DD.MM.YYYY");

  useEffect(() => {
    setBundeslaenderAuswahl(alleBundeslaender);
  }, []);

  useEffect(() => {
    searchOrganisation();
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchOrganisationHandler = (evt: any) => {
    if (evt.preventDefault) {
      evt.preventDefault();
    }
    if (evt?.type === "click" || (evt?.type === "keyup" && evt?.code === "Enter")) {
      setSelectedOrganisation(undefined);
      setLazyParams({
        first: 0,
        rows: 10,
        page: 0,
      });
    }
  };

  const searchOrganisation = () => {
    if (searchQuery.length > 1) {
      setLoading(true);

      const requestParameterSearchOrganisations: MitgliedschaftRequestType = {
        suchbegriff: searchQuery,
        sucheKammer: selectedBundesland,
        pageNumber: "" + (lazyParams.page + 1),
        pageSize: "" + lazyParams.rows,
      };

      mitgliedschaftService
        .searchBetreuerOrganisations(requestParameterSearchOrganisations)
        .then((prod) => {
          let data: MitgliedschaftResponseType = prod.data;
          if (data) {
            setSucheOrganisationenResult({
              ...sucheOrganisationenResult,
              ...data,
            });
            setTotalRecords(data.totalNrOfSearchResults ? data.totalNrOfSearchResults : 0);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const findLastSearchOrganisation = () => {
    setLoading(true);
    const requestParameter: MitgliedschaftRequestType = {};
    mitgliedschaftService
      .getBetreuerLetzteSuchen(requestParameter)
      .then((prod) => {
        let data: MitgliedschaftResponseType = prod.data;
        if (data) {
          setLetzteSucheOrganisationenResult({
            ...letzteSucheOrganisationenResult,
            ...data,
          });
          setTotalRecordsLetzteSuche(data.totalNrOfSearchResults ? data.totalNrOfSearchResults : 0);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onPage = (event: any) => {
    setLazyParams(event);
  };

  const onRowSelect = (event: any) => {
    toast.current?.show({
      severity: "info",
      summary: "Product Selected",
      detail: `Name: ${event.data.bezeichnung}`,
      life: 3000,
    });
  };

  const resetSelection = () => {
    setSelectedOrganisation(undefined);
  };

  const changeSelectedBundesland = (e: any) => {
    setSelectedOrganisation(undefined);
    setSelectedBundesland(e.target.value);
  };

  return (
    <>
      {(props.userInfo?.authenticatedUserMemberId !== "0" && props.userInfo?.personenbetreuer && (
        <>
          {!selectedOrganisation && (
            <main data-gtm-block="main">
              {!acceptedTerms && (
                <>
                  <section className="members bg--white" data-gtm-section="Mein WKO - eService Personenbetreuung">
                    <div className="container col-lg-7">
                      <div className="members-header">
                        <div className="headline">
                          <div className="headline-bc">
                            <h1>Personenbetreuung</h1>
                            <h3 className="subtitle">Gewerbeausübung für Personenbetreuung</h3>
                          </div>
                        </div>
                      </div>
                      <div className="content">
                        <p>
                          Sehr geehrtes Mitglied, <br></br>
                          um dieses eService nutzen zu können, ist die einmalige Zustimmung Ihrer Agentur erforderlich.{" "}
                          <br></br>
                          <br></br>
                          <span className="bold">
                            Verbindliche Nutzungsregelung zur Verwendung des eServices für Ruhendmeldung und
                            Wiederaufnahme – für Personenbetreueragenturen.
                          </span>
                          <br></br>
                          <br></br>
                          Mit dem eService können Sie wichtige Daten einsehen. Sie haben Zugriff auf die Daten der
                          selbstständigen Personenbetreuer/innen. Es ist ersichtlich, ob ein Gewerbe besteht oder ruhend
                          gemeldet ist.<br></br>
                          <br></br>
                          Durch meine Zustimmung bestätige ich, dass ich im Rahmen der Nutzung des mir zur Verfügung
                          gestellten eServices der Fachgruppe Personenberatung und Personenbetreuung
                        </p>
                        <ul>
                          <li>
                            nur die Daten der selbständigen Personenbetreuer/innen bearbeite, mit welchen ich in einem
                            aktiven Vertragsverhältnis (Organisationsvertrag) stehe, die Daten korrekt eingebe und für
                            jeden Schaden, der aufgrund einer missbräuchlichen oder fahrlässigen Eingabe von Daten
                            entsteht, im Rahmen des Schadenersatzrechts voll verantwortlich bin.
                          </li>
                          <li>
                            den Datenschutz einhalte:<br></br>
                            Es gelten die EU Datenschutzgrundverordnung (DSGVO) und das DSG (idF
                            Datenschutz-Anpassungsgesetz 2018). Die Daten dürfen nur im Rahmen der elektronischen
                            Nichtbetrieb-, Wiederbetriebs-Meldungen von berechtigten Benutzern für Personenbetreuer, die
                            dem Benutzer eine Vollmacht erteilt haben, verwendet werden. Eine Weitergabe von Daten oder
                            dem persönlichen Zugang an Dritte sowie eine missbräuchliche Verwendung ist untersagt.
                          </li>
                          <li>die aktuelle Vollmacht als pdf-Dokument im vorgesehenen Feld abspeichere und</li>
                          <li>
                            nach den Standes- und Ausübungsregeln für die Organisation von Personenbetreuung der jeweils
                            gültigen Fassung handle
                          </li>
                        </ul>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="acceptTermsCheckbox"
                            checked={acceptTermsChecked}
                            required={true}
                            onChange={(e) => setAcceptTermsChecked(e.currentTarget.checked)}
                          ></input>
                          <label className="form-check-label" htmlFor="acceptTermsCheckbox">
                            Zustimmung am {now}
                          </label>
                        </div>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            window.sessionStorage.setItem("personenbetreuerAcceptedTerms", "true");
                            setAcceptedTerms(true);
                            findLastSearchOrganisation();
                          }}
                          disabled={!acceptTermsChecked}
                        >
                          Weiter
                        </button>
                      </div>

                      <ServiceHotlineInfo hotlineKey="nbwb" userInfo={props.userInfo} />
                    </div>
                  </section>
                </>
              )}
              {acceptedTerms && (
                <>
                  <Toast ref={toast} />
                  <section className="members bg--white" data-gtm-section="Mein WKO - eService Personenbetreuung">
                    <div className="container col-lg-7">
                      <div className="members-header">
                        <div className="headline">
                          <div className="headline-bc">
                            <h1>Personenbetreuung</h1>
                            <h3 className="subtitle">Gewerbeausübung für Personenbetreuung</h3>
                          </div>
                        </div>
                      </div>
                      <div className="content search">
                        <div className="element-dropdown pe-9 col-9  d-flex align-items-center">
                          <BundeslaenderResultSelect
                            data={bundeslaenderAuswahl}
                            value={selectedBundesland}
                            onChangeHandler={changeSelectedBundesland}
                            addAlleOption={true}
                          />
                        </div>
                        <div className="element-input floating-label d-flex align-items-center mb-3">
                          <div className="element-text form-group">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control"
                                id="personenbetreuer_organisation_suche"
                                placeholder="Firma, Standort, Gewerbe, GISA, FN-Nr, …"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.currentTarget.value)}
                                onKeyUp={(e) => searchOrganisationHandler(e)}
                              />
                              <label htmlFor="personenbetreuer_organisation_suche">
                                Firma, Standort, GISA, FN-Nr, …
                              </label>
                            </div>
                          </div>
                          {loading && <Spinner />}
                          {!loading && (
                            <button
                              id="search-trigger"
                              type="button"
                              title="Suche starten"
                              aria-label="Suche starten"
                              className="search btn btn-primary"
                              onClick={(e) => searchOrganisationHandler(e)}
                            >
                              <IconSearch />
                            </button>
                          )}
                        </div>
                        {sucheOrganisationenResult && (
                          <DataTable
                            className="table table-responsive-xxl table-hover"
                            lazy
                            value={sucheOrganisationenResult?.organisationen}
                            dataKey="mitgliedsnummer"
                            selection={selectedOrganisation}
                            onSelectionChange={(e) => setSelectedOrganisation(e.value)}
                            onRowSelect={onRowSelect}
                            selectionMode="single"
                            currentPageReportTemplate="Ergebnisse {first} bis {last} von {totalRecords}"
                            paginator={totalRecords >= 5}
                            totalRecords={totalRecords}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            first={lazyParams.first}
                            onPage={onPage}
                            rows={lazyParams.rows}
                            loading={loading}
                            responsiveLayout="scroll"
                            emptyMessage="Kein Ergebnis für diese Filter!"
                          >
                            {selectedBundesland === "all" && (
                              <Column
                                field="wirtschaftskammerIdNeu"
                                header="Kammer"
                                body={(rowData) => {
                                  return getNameByWKIDNeu(rowData.wirtschaftskammerIdNeu);
                                }}
                              ></Column>
                            )}
                            <Column field="bezeichnung" header="Firmenname"></Column>
                            <Column field="status" header="Mitgliedsstatus"></Column>
                            <Column field="firmenbuchNummer" header="Firmenbuchnummer"></Column>
                            <Column field="geburtsdatum" header="Geburtsdatum"></Column>
                          </DataTable>
                        )}
                        {!sucheOrganisationenResult && letzteSucheOrganisationenResult && (
                          <div className="mt-5">
                            <h4 className="subtitle">Letzte bearbeitete Personenbetreuer</h4>
                            <DataTable
                              className="table table-responsive-xxl table-hover"
                              lazy
                              value={letzteSucheOrganisationenResult?.organisationen}
                              dataKey="mitgliedsnummer"
                              selection={selectedOrganisation}
                              onSelectionChange={(e) => setSelectedOrganisation(e.value)}
                              onRowSelect={onRowSelect}
                              selectionMode="single"
                              currentPageReportTemplate="Ergebnisse {first} bis {last} von {totalRecords}"
                              paginator={false}
                              totalRecords={totalRecordsLetzteSuche}
                              first={lazyParams.first}
                              onPage={onPage}
                              rows={lazyParams.rows}
                              loading={loading}
                              responsiveLayout="scroll"
                              emptyMessage="Kein Ergebnis für diese Filter!"
                            >
                              <Column
                                field="wirtschaftskammerIdNeu"
                                header="Kammer"
                                body={(rowData) => {
                                  return getNameByWKIDNeu(rowData.wirtschaftskammerIdNeu);
                                }}
                              ></Column>
                              <Column field="bezeichnung" header="Firmenname"></Column>
                              <Column field="geburtsdatum" header="Geburtsdatum"></Column>
                              <Column field="gisaZahl" header="GISA-Zahl"></Column>
                            </DataTable>
                          </div>
                        )}
                      </div>

                      <ServiceHotlineInfo hotlineKey="nbwb" userInfo={props.userInfo} />
                    </div>
                  </section>
                </>
              )}
            </main>
          )}
          {selectedOrganisation && (
            <>
              <RuhendWiederbetriebMeldungView
                userInfo={props.userInfo}
                selectedOrganisation={selectedOrganisation}
                resetOrganisationSelectionHandler={resetSelection}
                currentPath={props.currentPath}
              />
            </>
          )}
        </>
      )) || (
        <>
          <section className="members bg--white" data-gtm-section="Mein WKO - eService Personenbetreuung">
            <div className="container col-lg-7">
              <div className="members-header">
                <div className="headline">
                  <div className="headline-bc">
                    <h1>Personenbetreuung</h1>
                    <h3 className="subtitle">Gewerbeausübung für Personenbetreuung</h3>
                  </div>
                </div>
              </div>
              <p>Sie besitzen leider keine Berechtigung für dieses Service mit der ausgewählten Benutzerrolle.</p>
              <p>&nbsp;</p>
              <ServiceHotlineInfo hotlineKey="nbwb" userInfo={props.userInfo} />
            </div>
          </section>
        </>
      )}
    </>
  );
}
