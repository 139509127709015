/* tslint:disable */
/* eslint-disable */
/**
 * MeinWKO - REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FoPaketeResponseType } from '../model';
// @ts-ignore
import { KontaktdatenRequestModel } from '../model';
// @ts-ignore
import { KontaktdatenResponseType } from '../model';
// @ts-ignore
import { KontaktdatenStatusModel } from '../model';
// @ts-ignore
import { MitgliedschaftRequestType } from '../model';
// @ts-ignore
import { MitgliedschaftResponseType } from '../model';
/**
 * MitgliedschaftApi - axios parameter creator
 * @export
 */
export const MitgliedschaftApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkKontaktdatenChangeRequestStatus: async (mitgliedschaftRequestType?: MitgliedschaftRequestType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/mitgliedschaft/kontaktdaten/checkStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mitgliedschaftRequestType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkMitgliederServicesStammblattBerechtigungsSize: async (mitgliedschaftRequestType?: MitgliedschaftRequestType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/mitgliedschaft/stammblattCheckBerechtigungSize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mitgliedschaftRequestType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KontaktdatenRequestModel} [kontaktdatenRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKontaktdatenChangeRequest: async (kontaktdatenRequestModel?: KontaktdatenRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/mitgliedschaft/kontaktdaten/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kontaktdatenRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFoPakete: async (mitgliedschaftRequestType?: MitgliedschaftRequestType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/mitgliedschaft/foPakete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mitgliedschaftRequestType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKontaktdaten: async (mitgliedschaftRequestType?: MitgliedschaftRequestType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/mitgliedschaft/kontaktdaten`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mitgliedschaftRequestType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMitgliederServicesNBWBBetreuerLetzteOrganisationen: async (mitgliedschaftRequestType?: MitgliedschaftRequestType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/mitgliedschaft/nbwbLetzteOrganisationen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mitgliedschaftRequestType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMitgliederServicesNBWBBetreuerSearchOrganisationen: async (mitgliedschaftRequestType?: MitgliedschaftRequestType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/mitgliedschaft/nbwbSearchOrganisationen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mitgliedschaftRequestType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMitgliederServicesNBWBMGBerechtigungen: async (mitgliedschaftRequestType?: MitgliedschaftRequestType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/mitgliedschaft/nbwbBerechtigungen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mitgliedschaftRequestType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMitgliederServicesNBWBMGLetzteMeldungen: async (mitgliedschaftRequestType?: MitgliedschaftRequestType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/mitgliedschaft/nbwbLetzteMeldungen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mitgliedschaftRequestType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMitgliederServicesStammblattBundeslaender: async (mitgliedschaftRequestType?: MitgliedschaftRequestType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/mitgliedschaft/stammblattBundeslaender`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mitgliedschaftRequestType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMitgliederServicesStammblattStandorte: async (mitgliedschaftRequestType?: MitgliedschaftRequestType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/mitgliedschaft/stammblattStandorte`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mitgliedschaftRequestType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMitgliederServicesStammblattSuche: async (mitgliedschaftRequestType?: MitgliedschaftRequestType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/mitgliedschaft/stammblattSuche`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mitgliedschaftRequestType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMitgliedschaftInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/mitgliedschaft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isKontaktdatenEnabled: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/mitgliedschaft/kontaktdaten/enabled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMitgliederServicesNBWBMGMeldungen: async (mitgliedschaftRequestType?: MitgliedschaftRequestType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/mitgliedschaft/nbwbMeldungen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mitgliedschaftRequestType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMitgliederServicesStammblattBestellung: async (mitgliedschaftRequestType?: MitgliedschaftRequestType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/mitgliedschaft/stammblattBestellung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mitgliedschaftRequestType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MitgliedschaftApi - functional programming interface
 * @export
 */
export const MitgliedschaftApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MitgliedschaftApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkKontaktdatenChangeRequestStatus(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KontaktdatenStatusModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkKontaktdatenChangeRequestStatus(mitgliedschaftRequestType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkMitgliederServicesStammblattBerechtigungsSize(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MitgliedschaftResponseType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkMitgliederServicesStammblattBerechtigungsSize(mitgliedschaftRequestType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {KontaktdatenRequestModel} [kontaktdatenRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createKontaktdatenChangeRequest(kontaktdatenRequestModel?: KontaktdatenRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createKontaktdatenChangeRequest(kontaktdatenRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFoPakete(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoPaketeResponseType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFoPakete(mitgliedschaftRequestType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKontaktdaten(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KontaktdatenResponseType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKontaktdaten(mitgliedschaftRequestType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMitgliederServicesNBWBBetreuerLetzteOrganisationen(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MitgliedschaftResponseType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMitgliederServicesNBWBBetreuerLetzteOrganisationen(mitgliedschaftRequestType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMitgliederServicesNBWBBetreuerSearchOrganisationen(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MitgliedschaftResponseType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMitgliederServicesNBWBBetreuerSearchOrganisationen(mitgliedschaftRequestType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMitgliederServicesNBWBMGBerechtigungen(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MitgliedschaftResponseType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMitgliederServicesNBWBMGBerechtigungen(mitgliedschaftRequestType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMitgliederServicesNBWBMGLetzteMeldungen(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MitgliedschaftResponseType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMitgliederServicesNBWBMGLetzteMeldungen(mitgliedschaftRequestType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMitgliederServicesStammblattBundeslaender(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MitgliedschaftResponseType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMitgliederServicesStammblattBundeslaender(mitgliedschaftRequestType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMitgliederServicesStammblattStandorte(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MitgliedschaftResponseType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMitgliederServicesStammblattStandorte(mitgliedschaftRequestType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMitgliederServicesStammblattSuche(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MitgliedschaftResponseType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMitgliederServicesStammblattSuche(mitgliedschaftRequestType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMitgliedschaftInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMitgliedschaftInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isKontaktdatenEnabled(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isKontaktdatenEnabled(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveMitgliederServicesNBWBMGMeldungen(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MitgliedschaftResponseType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveMitgliederServicesNBWBMGMeldungen(mitgliedschaftRequestType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveMitgliederServicesStammblattBestellung(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MitgliedschaftResponseType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveMitgliederServicesStammblattBestellung(mitgliedschaftRequestType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MitgliedschaftApi - factory interface
 * @export
 */
export const MitgliedschaftApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MitgliedschaftApiFp(configuration)
    return {
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkKontaktdatenChangeRequestStatus(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: any): AxiosPromise<KontaktdatenStatusModel> {
            return localVarFp.checkKontaktdatenChangeRequestStatus(mitgliedschaftRequestType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkMitgliederServicesStammblattBerechtigungsSize(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: any): AxiosPromise<MitgliedschaftResponseType> {
            return localVarFp.checkMitgliederServicesStammblattBerechtigungsSize(mitgliedschaftRequestType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KontaktdatenRequestModel} [kontaktdatenRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKontaktdatenChangeRequest(kontaktdatenRequestModel?: KontaktdatenRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.createKontaktdatenChangeRequest(kontaktdatenRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFoPakete(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: any): AxiosPromise<FoPaketeResponseType> {
            return localVarFp.getFoPakete(mitgliedschaftRequestType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKontaktdaten(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: any): AxiosPromise<KontaktdatenResponseType> {
            return localVarFp.getKontaktdaten(mitgliedschaftRequestType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMitgliederServicesNBWBBetreuerLetzteOrganisationen(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: any): AxiosPromise<MitgliedschaftResponseType> {
            return localVarFp.getMitgliederServicesNBWBBetreuerLetzteOrganisationen(mitgliedschaftRequestType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMitgliederServicesNBWBBetreuerSearchOrganisationen(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: any): AxiosPromise<MitgliedschaftResponseType> {
            return localVarFp.getMitgliederServicesNBWBBetreuerSearchOrganisationen(mitgliedschaftRequestType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMitgliederServicesNBWBMGBerechtigungen(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: any): AxiosPromise<MitgliedschaftResponseType> {
            return localVarFp.getMitgliederServicesNBWBMGBerechtigungen(mitgliedschaftRequestType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMitgliederServicesNBWBMGLetzteMeldungen(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: any): AxiosPromise<MitgliedschaftResponseType> {
            return localVarFp.getMitgliederServicesNBWBMGLetzteMeldungen(mitgliedschaftRequestType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMitgliederServicesStammblattBundeslaender(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: any): AxiosPromise<MitgliedschaftResponseType> {
            return localVarFp.getMitgliederServicesStammblattBundeslaender(mitgliedschaftRequestType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMitgliederServicesStammblattStandorte(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: any): AxiosPromise<MitgliedschaftResponseType> {
            return localVarFp.getMitgliederServicesStammblattStandorte(mitgliedschaftRequestType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMitgliederServicesStammblattSuche(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: any): AxiosPromise<MitgliedschaftResponseType> {
            return localVarFp.getMitgliederServicesStammblattSuche(mitgliedschaftRequestType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMitgliedschaftInfo(options?: any): AxiosPromise<void> {
            return localVarFp.getMitgliedschaftInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isKontaktdatenEnabled(options?: any): AxiosPromise<void> {
            return localVarFp.isKontaktdatenEnabled(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMitgliederServicesNBWBMGMeldungen(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: any): AxiosPromise<MitgliedschaftResponseType> {
            return localVarFp.saveMitgliederServicesNBWBMGMeldungen(mitgliedschaftRequestType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMitgliederServicesStammblattBestellung(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: any): AxiosPromise<MitgliedschaftResponseType> {
            return localVarFp.saveMitgliederServicesStammblattBestellung(mitgliedschaftRequestType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MitgliedschaftApi - object-oriented interface
 * @export
 * @class MitgliedschaftApi
 * @extends {BaseAPI}
 */
export class MitgliedschaftApi extends BaseAPI {
    /**
     * 
     * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MitgliedschaftApi
     */
    public checkKontaktdatenChangeRequestStatus(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig) {
        return MitgliedschaftApiFp(this.configuration).checkKontaktdatenChangeRequestStatus(mitgliedschaftRequestType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MitgliedschaftApi
     */
    public checkMitgliederServicesStammblattBerechtigungsSize(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig) {
        return MitgliedschaftApiFp(this.configuration).checkMitgliederServicesStammblattBerechtigungsSize(mitgliedschaftRequestType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KontaktdatenRequestModel} [kontaktdatenRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MitgliedschaftApi
     */
    public createKontaktdatenChangeRequest(kontaktdatenRequestModel?: KontaktdatenRequestModel, options?: AxiosRequestConfig) {
        return MitgliedschaftApiFp(this.configuration).createKontaktdatenChangeRequest(kontaktdatenRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MitgliedschaftApi
     */
    public getFoPakete(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig) {
        return MitgliedschaftApiFp(this.configuration).getFoPakete(mitgliedschaftRequestType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MitgliedschaftApi
     */
    public getKontaktdaten(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig) {
        return MitgliedschaftApiFp(this.configuration).getKontaktdaten(mitgliedschaftRequestType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MitgliedschaftApi
     */
    public getMitgliederServicesNBWBBetreuerLetzteOrganisationen(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig) {
        return MitgliedschaftApiFp(this.configuration).getMitgliederServicesNBWBBetreuerLetzteOrganisationen(mitgliedschaftRequestType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MitgliedschaftApi
     */
    public getMitgliederServicesNBWBBetreuerSearchOrganisationen(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig) {
        return MitgliedschaftApiFp(this.configuration).getMitgliederServicesNBWBBetreuerSearchOrganisationen(mitgliedschaftRequestType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MitgliedschaftApi
     */
    public getMitgliederServicesNBWBMGBerechtigungen(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig) {
        return MitgliedschaftApiFp(this.configuration).getMitgliederServicesNBWBMGBerechtigungen(mitgliedschaftRequestType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MitgliedschaftApi
     */
    public getMitgliederServicesNBWBMGLetzteMeldungen(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig) {
        return MitgliedschaftApiFp(this.configuration).getMitgliederServicesNBWBMGLetzteMeldungen(mitgliedschaftRequestType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MitgliedschaftApi
     */
    public getMitgliederServicesStammblattBundeslaender(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig) {
        return MitgliedschaftApiFp(this.configuration).getMitgliederServicesStammblattBundeslaender(mitgliedschaftRequestType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MitgliedschaftApi
     */
    public getMitgliederServicesStammblattStandorte(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig) {
        return MitgliedschaftApiFp(this.configuration).getMitgliederServicesStammblattStandorte(mitgliedschaftRequestType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MitgliedschaftApi
     */
    public getMitgliederServicesStammblattSuche(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig) {
        return MitgliedschaftApiFp(this.configuration).getMitgliederServicesStammblattSuche(mitgliedschaftRequestType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MitgliedschaftApi
     */
    public getMitgliedschaftInfo(options?: AxiosRequestConfig) {
        return MitgliedschaftApiFp(this.configuration).getMitgliedschaftInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MitgliedschaftApi
     */
    public isKontaktdatenEnabled(options?: AxiosRequestConfig) {
        return MitgliedschaftApiFp(this.configuration).isKontaktdatenEnabled(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MitgliedschaftApi
     */
    public saveMitgliederServicesNBWBMGMeldungen(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig) {
        return MitgliedschaftApiFp(this.configuration).saveMitgliederServicesNBWBMGMeldungen(mitgliedschaftRequestType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MitgliedschaftRequestType} [mitgliedschaftRequestType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MitgliedschaftApi
     */
    public saveMitgliederServicesStammblattBestellung(mitgliedschaftRequestType?: MitgliedschaftRequestType, options?: AxiosRequestConfig) {
        return MitgliedschaftApiFp(this.configuration).saveMitgliederServicesStammblattBestellung(mitgliedschaftRequestType, options).then((request) => request(this.axios, this.basePath));
    }
}
