import * as React from "react";
import MitgliedschaftController from "./controller/MitgliedschaftController";
import { PropsViews } from "./model/PropsInterfaces";

export default function Mitgliedschaft(options: PropsViews) {
  return (
    <>
      <MitgliedschaftController
        currentView={options.currentView}
        currentPath={options.currentPath}
      />
    </>
  );
}
