import Modal from "react-bootstrap/Modal";
import IconHinweis from "../svgs/IconHinweis";
import { URL_NACHRICHTEN, getLink } from "../constants/urls";

interface PropsModalComponent {
  show: boolean;
  modalTitle?: string;
  errorTitle?: string;
  errorMessages?: Array<string>;
  okButtonPath?: string;
  viewHandler?: (accepted: boolean) => any;
  onHide: () => void;
}

interface PropsFileUploadModal {
  modalId: string;
  show: boolean;
  resetViewHandler: (fireCallback: boolean) => any;
  onHide: () => void;
}

export function ModalError(options: PropsModalComponent) {
  return (
    <Modal
      show={options.show}
      onHide={options.onHide}
      size="lg"
      aria-labelledby="exampleModalLabel"
      centered
      tabIndex={-1}
      onShow={() => {
        window.scrollTo(0, 0);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {options.modalTitle ? (
            <>{options.modalTitle}</>
          ) : (
            <>Die Anfrage lieferte einen Fehler zurück</>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="content d-flex mb-3">
          <IconHinweis
            style={{
              width: "220px",
              height: "100px",
              marginRight: "2rem",
              paddingLeft: "10px",
            }}
          />
          <div className="content pe-3 me-2">
            {options.errorTitle ? (
              <>
                <p className="modal-title-legacy">{options.errorTitle}</p>
                <p>&nbsp;</p>
              </>
            ) : (
              <></>
            )}
            {options.errorMessages?.map((m, index) => {
              return <div key={index} dangerouslySetInnerHTML={{__html: m}} />;
            })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export function ModalAccept(options: PropsModalComponent) {
  return (
    <Modal
      show={options.show}
      onHide={options.onHide}
      size="lg"
      aria-labelledby="exampleModalLabel"
      centered
      tabIndex={-1}
      onShow={() => {
        window.scrollTo(0, 0);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {options.modalTitle ? (
            <>{options.modalTitle}</>
          ) : (
            <>Die Anfrage lieferte einen Fehler zurück</>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="content d-flex mb-3">
          <IconHinweis
            style={{
              width: "220px",
              height: "100px",
              marginRight: "2rem",
              paddingLeft: "10px",
            }}
          />
          <div className="content pe-3 me-2">
            {options.errorTitle ? (
              <>
                <p className="modal-title-legacy">{options.errorTitle}</p>
                <p>&nbsp;</p>
              </>
            ) : (
              <></>
            )}
            {options.errorMessages?.map((m, index) => {
              return <div key={index} dangerouslySetInnerHTML={{__html: m}} />;
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => options.viewHandler?options.viewHandler(false):function(){}}
        >
          Abbrechen
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => options.viewHandler?options.viewHandler(true):function(){}}
        >
          Akzeptieren
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export function ModalSuccessDialog(options: PropsModalComponent) {
  return (
    <Modal
      show={options.show}
      onHide={options.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onShow={() => {
        window.scrollTo(0, 0);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Vielen Dank für Ihre Meldung.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Ihr Antrag wurde erfolgreich entgegengenommen. Sie finden Ihre
          Meldung(en) unter "Nachrichten" auf WKO.at
        </p>
      </Modal.Body>
      <Modal.Footer>
        <a
          href={getLink(URL_NACHRICHTEN)}
          target="_blank"
          rel="noreferrer"
          className="btn btn-primary"
        >
          Meine Nachrichten
        </a>
        <a
          href={options.okButtonPath}
          type="button"
          className="btn btn-secondary"
        >
          Ok
        </a>
      </Modal.Footer>
    </Modal>
  );
}

export function DeleteModal(props: PropsFileUploadModal) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      centered
      tabIndex={-1}
      aria-labelledby="deleteModalLabel"
    >
      <Modal.Header closeButton>
        <h1 className="modal-title fs-5">Dokument löschen</h1>
      </Modal.Header>
      <Modal.Body>Sind Sie sicher das Dokument zu löschen?</Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => props.resetViewHandler(false)}
        >
          Abbrechen
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => props.resetViewHandler(true)}
        >
          Ja, bitte löschen
        </button>
      </Modal.Footer>
    </Modal>
  );
}
