import { CSSProperties } from "react";
import IconHinweis from "../svgs/IconHinweis";
import { BasicUserInfoResponseType } from "../../../api";

interface PropsHinweis {
  title?: string;
  link?: string;
  message: string;
  marginBottom?: boolean;
  style?: CSSProperties;
  userInfo?: BasicUserInfoResponseType;
}

export function Hinweis(options: PropsHinweis) {
  const innerContainer = (
    <div
      className={
        options.marginBottom
          ? "alert alert-box alert-info border-0 rounded-0 bg--peach-secondary mb-5"
          : "alert alert-box alert-info border-0 rounded-0 bg--peach-secondary"
      }
      role="alert"
      data-gtm-box="Hinweis"
    >
      <div className="alert-info-bulb">
        <IconHinweis style={options.style} />
        <span>Hinweis</span>
      </div>
      <div className="alert-info-content">
        {options.title && <p className="title">{options.title}</p>}
        <p>{options.message} </p>
      </div>
    </div>
  );

  return (
    <>
      {(options.link && options.userInfo && (
        <a href={options.link} style={{ textDecoration: "none" }}>
          {innerContainer}
        </a>
      )) || <>{innerContainer}</>}
    </>
  );
}

export function NBWBHinweisBerechtigungen() {
  return <Hinweis message="Wählen Sie eine oder mehrere Berechtigungen, um Ihre Auswahl weiter einzuschränken" />;
}

export function NBWBHinweisBestaetigung() {
  return (
    <Hinweis
      message="Die Bestätigung wird als PDF-Dokument erstellt und an Ihr WKO-Postfach zugestellt."
      marginBottom={true}
    />
  );
}

export function StammblattHinweisBestaetigung() {
  return (
    <Hinweis message="Die Bestätigung wird als PDF-Dokument erstellt und an Ihr WKO-Postfach zugestellt. Sie können es zusätzlich an eine beliebige E-Mail senden." />
  );
}
