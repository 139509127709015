export default function IconDelete21() {
  return (
    <svg
      className="icon-delete-21"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 21"
    >
      <g
        id="streamlinehq-bin-2-alternate-interface-essential-40"
        transform="translate(1.48 1.787)"
      >
        <path
          id="Pfad_2800"
          data-name="Pfad 2800"
          d="M1.751,5.286,17.2,2"
          transform="translate(0 -0.31)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Pfad_2801"
          data-name="Pfad 2801"
          d="M12.215.775l-3.31.7a1.128,1.128,0,0,0-.871,1.34l.235,1.1L13.787,2.75l-.235-1.1A1.128,1.128,0,0,0,12.215.775Z"
          transform="translate(-1.55 0)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Pfad_2802"
          data-name="Pfad 2802"
          d="M10.751,16.142V10.5"
          transform="translate(-2.23 -2.416)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Pfad_2803"
          data-name="Pfad 2803"
          d="M15.251,16.142V10.5"
          transform="translate(-3.345 -2.416)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Pfad_2804"
          data-name="Pfad 2804"
          d="M15.275,6h1.975L16.208,17.941a1.128,1.128,0,0,1-1.128,1.035H7A1.128,1.128,0,0,1,5.88,17.941L5.1,8.54"
          transform="translate(-0.83 -1.301)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
