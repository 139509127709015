import { ServiceHotlineInfo } from "../view/InfoComponents";
import { Spinner } from "../view/Spinner";
import IconSearch from "../svgs/IconSearch";

import { MitgliedschaftRequestType, MitgliedschaftResponseType } from "../../../api";
import { mitgliedschaftService } from "../service/MitgliedschaftService";
import { useEffect, useRef, useState } from "react";

import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Organisation } from "../../../api/model/organisation";
import StammblattView from "./Stammblatt";
import { PropsViews } from "../model/PropsInterfaces";
import { BundeslaenderResultSelect } from "../form/Selects";
import { alleBundeslaender } from "../model/Kammern";

export default function StammblattSucheView(props: PropsViews) {
  const [sucheOrganisationenResult, setSucheOrganisationenResult] = useState<MitgliedschaftResponseType>();
  const [bundeslaenderAuswahl, setBundeslaenderAuswahl] = useState<MitgliedschaftResponseType>();
  const [selectedBundesland, setSelectedBundesland] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
  });
  const [selectedOrganisation, setSelectedOrganisation] = useState<Organisation | undefined>(undefined);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    searchOrganisation();
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setBundeslaenderAuswahl(alleBundeslaender);
  }, []);

  const searchOrganisationHandler = (evt: any) => {
    if (evt.preventDefault) {
      evt.preventDefault();
    }
    if (evt?.type === "click" || (evt?.type === "keyup" && evt?.code === "Enter")) {
      setSelectedOrganisation(undefined);
      setLazyParams({
        first: 0,
        rows: 10,
        page: 0,
      });
    }
  };

  const searchOrganisation = () => {
    if (searchQuery.length > 1) {
      setLoading(true);

      const requestParameterSearchOrganisations: MitgliedschaftRequestType = {
        suchbegriff: searchQuery,
        sucheKammer: selectedBundesland === "all" ? "-" : selectedBundesland,
        pageNumber: "" + (lazyParams.page + 1),
        pageSize: "" + lazyParams.rows,
      };

      mitgliedschaftService
        .searchOrganisations(requestParameterSearchOrganisations)
        .then((prod) => {
          let data: MitgliedschaftResponseType = prod.data;
          if (data?.erfolgreich) {
            setSucheOrganisationenResult({
              ...sucheOrganisationenResult,
              ...data,
            });
            setTotalRecords(data.totalNrOfSearchResults ? data.totalNrOfSearchResults : 0);
            window.dataLayer.push({
              event: "search-result",
              searchTerm: searchQuery,
              searchCategory: "eserviceMitgliedschaftFirmenauskunft",
              searchResults: data.totalNrOfSearchResults ? data.totalNrOfSearchResults : 0,
              searchResultsPage: lazyParams.page + 1,
              searchResultsView: lazyParams.rows,
            });
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const onPage = (event: any) => {
    setLazyParams(event);
  };

  const onRowSelect = (event: any) => {
    toast.current?.show({
      severity: "info",
      summary: "Product Selected",
      detail: `Name: ${event.data.bezeichnung}`,
      life: 3000,
    });
  };

  const resetSelection = () => {
    setSelectedOrganisation(undefined);
  };

  return (
    <>
      {!selectedOrganisation && (
        <main data-gtm-block="main">
          <Toast ref={toast} />
          <section className="members bg--white" data-gtm-section="Mein WKO - eService Firmenauskunft">
            <div className="container col-lg-7">
              <h1>Firmenauskunft</h1>

              {((props.userInfo?.authenticatedUserMemberId !== "0" || props.userInfo?.stammblattErsteller) && (
                <>
                  <div className="content search">
                    <div className="element-dropdown pe-9 col-9  d-flex align-items-center">
                      <BundeslaenderResultSelect
                        data={bundeslaenderAuswahl}
                        value={selectedBundesland}
                        onChangeHandler={(e) => {
                          setSelectedBundesland(e.target.value);
                        }}
                        addAlleOption={true}
                      />
                    </div>
                    <div className="element-input floating-label d-flex align-items-center mb-3">
                      <div className="element-text form-group">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="organisation_suche"
                            placeholder="Firma, Standort, Gewerbe, GISA, FN-Nr, …"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.currentTarget.value)}
                            onKeyUp={(e) => searchOrganisationHandler(e)}
                          />
                          <label htmlFor="organisation_suche">Firma, Standort, GISA, FN-Nr, …</label>
                        </div>
                      </div>
                      {loading && <Spinner />}
                      {!loading && (
                        <button
                          id="search-trigger"
                          type="button"
                          title="Suche starten"
                          aria-label="Suche starten"
                          className="search btn btn-primary"
                          onClick={(e) => searchOrganisationHandler(e)}
                        >
                          <IconSearch />
                        </button>
                      )}
                    </div>
                    {sucheOrganisationenResult && (
                      <DataTable
                        className="table table-responsive-xxl table-hover"
                        lazy
                        value={sucheOrganisationenResult?.organisationen}
                        dataKey="mitgliedsnummer"
                        selection={selectedOrganisation}
                        onSelectionChange={(e) => setSelectedOrganisation(e.value)}
                        onRowSelect={onRowSelect}
                        selectionMode="single"
                        currentPageReportTemplate="Ergebnisse {first} bis {last} von {totalRecords}"
                        paginator={sucheOrganisationenResult?.organisationen && totalRecords >= 5}
                        totalRecords={totalRecords}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        first={lazyParams.first}
                        onPage={onPage}
                        rows={lazyParams.rows}
                        loading={loading}
                        responsiveLayout="scroll"
                        emptyMessage="Kein Ergebnis für diese Filter!"
                      >
                        <Column field="bezeichnung" header="Firmenname"></Column>
                        <Column field="status" header="Mitgliedsstatus"></Column>
                        <Column field="firmenbuchNummer" header="Firmenbuchnummer"></Column>
                        <Column field="geburtsdatum" header="Geburtsdatum"></Column>
                      </DataTable>
                    )}
                  </div>
                </>
              )) || (
                <>
                  <p>Sie besitzen leider keine Berechtigung für dieses Service mit der ausgewählten Benutzerrolle.</p>
                  <p>&nbsp;</p>
                </>
              )}

              <ServiceHotlineInfo hotlineKey="stammblatt" userInfo={props.userInfo} />
            </div>
          </section>
        </main>
      )}
      {selectedOrganisation && (
        <>
          <StammblattView
            userInfo={props.userInfo}
            selectedOrganisation={selectedOrganisation}
            resetOrganisationSelectionHandler={resetSelection}
            currentPath={props.currentPath}
          />
        </>
      )}
    </>
  );
}
