import axios, { AxiosInstance } from "axios";
import { loadingStatusProvider } from "../provider/LoadingStatusProvider";

class AxiosFactory {
  private static _instance: AxiosInstance;

  private constructor() {}

  public static get Instance() {
    return this._instance || (this._instance = this.createAxiosInstance());
  }

  static createAxiosInstance(): AxiosInstance {
    const axiosInstance = axios.create();

    this.addLoadingIndicatorInterceptors(axiosInstance);

    return axiosInstance;
  }

  private static addLoadingIndicatorInterceptors(axiosInstance: AxiosInstance) {
    axiosInstance.interceptors.request.use(function (config) {
      loadingStatusProvider.increaseLoadingCount();
      return config;
    });

    axiosInstance.interceptors.response.use(
      function (response) {
        loadingStatusProvider.decreaseLoadingCount();
        return response;
      },
      function (error) {
        loadingStatusProvider.decreaseLoadingCount();
        return Promise.reject(error);
      }
    );
  }
}

export const axiosInstance = AxiosFactory.Instance;
